import React, { useEffect } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import bannerHome from "../../assets/img/banners/servico-premiado/banner-home.jpg";

import "./valecombustivel.scss";

import StatusRecall from "../../components/StatusRecall/StatusRecall";
import { useHistory } from "react-router-dom";
import Mask from "../../services/mask";
//import Utils from '../../services/serviceUtils';

import { trackPromise } from "react-promise-tracker";
import ApiServices from "../../services/apiServices";

/////////////////////////////
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";

//import { saveAs } from 'file-saver';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import HelpOutline from "@material-ui/icons/HelpOutline";

import Regulamento from "../../components/Docs/RegulamentoBrinde";
import BannerInicial from "../../assets/black/bannerInicial.png"
 
import volante from "../../assets/img/volante-line.png";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import storage from "../../services/storage";

const Recall = (props) => {
  //const dateNow = new Date().toISOString()
const history = useHistory()
  const [openModal, setOpenModal, setModalContent, modalContent, purify] =
    React.useState(false);

  return (
    <>
      <DialogContent>
        z
        {props.type === "pendente" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <StatusRecall status={false} />
              <p className="big bold">Data de atualização: {props.date}</p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>Agende agora seu Recall de Airbags Takata</h3>
            </div>
            <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
              <a
                href="https://www.chevrolet.com.br/servicos/recalls/airbag-takata/solicitar-troca"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-block btn-lg"
              >
                Clique e agende
              </a>
            </div>
          </div>
        )}
        {props.type === "naoEnviado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                não envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <p className="big bold">
                Data de atualização: {props.date}
                {/* {moment(dateNow).format('DD/MM/YYYY')} */}
              </p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>
                Saiba se seu veículo está participando de algum outro Recall da
                Chevrolet.
              </h3>
            </div>
            <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
              <a
                href="https://www.chevrolet.com.br/servicos/recalls"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-block btn-lg"
              >
                Consulte
              </a>
            </div>
          </div>
        )}
        {props.type === "executado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <StatusRecall status={true} />
              <p className="big bold text-center">
                Serviço realizado em: {props.servico}
              </p>
              <p className="big bold">Data de atualização: {props.date}</p>
            </div>

            {/* <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
                          <button className="btn btn-primary btn-block btn-lg">
                              Conheça o regulamento do sorteio
                          </button>
                      </div> */}
          </div>
        )}
        {/* {props.type !== "pendente" &&
            props.type !== "naoEnviado" &&
            props.type !== "executado" && (
              <div className="row">
                <div className="col-md-12 col-sm-12">Status não encontrado</div>
              </div>
            )} */}
      </DialogContent>
    </>
  );
};
const ModalCpf = (props) => {
  return (
    <DialogContent>
      {props.status === "disponivel" && (
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <h3>Registro - CPF Localizado</h3>
            <p className="big bold">Data de atualização: {props.date}</p>

            <p className="big mt30">Crédito disponível para uso.</p>
          </div>
        </div>
      )}

      {props.status === "localizado" && (
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <h3>Registro - CPF Localizado</h3>
            <p className="big bold">Data de atualização: {props.date}</p>

            <p className="big mt30">
              Aguardando processamento e validação da documentação.
            </p>
          </div>
        </div>
      )}

      {props.status === "naoLocalizado" && (
        <div className="row">
          <div className="col-md-4 col-sm-3 col-5 mb20">
            <img
              src={volante}
              alt="volante"
              className="responsive"
              style={{ marginLeft: "-60px" }}
            />
          </div>
          <div className="col-md-8 col-sm-12">
            <h3>Registro - CPF não localizado</h3>
            <p className="big">Data de atualização: {props.date}</p>

            <h3 className="mt30">Já fez o Recall?</h3>
            <p>
              Caso você tenha efetuado o Recall de Airbags Takata do Celta (ano
              de fabricação 2013 a 2016) ou Classic (ano de fabricação 2012 a
              2016) na validade da Campanha, contate a Concessionaria Chevrolet
              responsável pelo seu Recall e solicite o seu cadastro. O
              processamento pode demorar até 5 dias após a realização do Recall.
            </p>

            <Regulamento textoBtn={"Conheça o regulamento da Campanha"} />
            {/* styleBtn={'container'}  <button className="btn btn-primary btn-block btn-lg mt30">
                            Conheça o regulamento do sorteio
                        </button> */}
          </div>
        </div>
      )}

      {props.status !== "disponivel" &&
        props.status !== "localizado" &&
        props.status !== "naoLocalizado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p>Status não localizado</p>
            </div>
          </div>
        )}
    </DialogContent>
  );
};

function ValeCombustivel(props) {
  const [cpf, setCpf] = React.useState("");
  const [cpfResponse, setCpfResponse] = React.useState("");
  const [cpfData, setCpfData] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [openCpf, setOpenCpf] = React.useState(false);
  const modalOpenCpf = () => {
    setOpenCpf(true);
  };
  const modalCloseCpf = () => {
    setOpenCpf(false);
  };

  const [chassi, setChassi] = React.useState("");
  const [chassiResponse, setChassiResponse] = React.useState("");
  const [chassiData, setChassiData] = React.useState("");
  const [dataServico, setdataServico] = React.useState("");

  // const [usuarioLogado, setUsuarioLogado] = React.useState([]);
  useEffect(()=>{
    // storage.set('CONTADORBALAO', 38)
  }, [])
  const modalOpenRecall = () => {
    setOpen(true);
  };
  const modalCloseRecall = () => {
    setOpen(false);
  };

  const changeCPF = (event) => {
  
    setCpf(Mask.cpf(event.target.value));
  };

  const sendChassi = () => {
    if (chassi.length === 17) {
    
      trackPromise(
        //ApiServices.apiPost('valeCombustivel/buscarChassi', {chassi})
        ApiServices.apiGet(`valeCombustivel/statusChassi?chassi=${chassi}`)
          .then((res) => {
            if (res.success) {
              if (res.data.statusCodido === "1") {
                setChassiResponse("naoEnviado");
              } else if (res.data.statusCodido === "2") {
                setChassiResponse("executado");
              } else if (res.data.statusCodido === "3") {
                setChassiResponse("pendente");
              }

              setChassiData(res.data.dataAtualizacao);
              setdataServico(res.data.dataServico);

              modalOpenRecall();
            } else {
              props.showMsgW(res.errors);
            }
          })
          .catch((res) => {
            props.showMsgW(`Erro ao buscar chassi`);
          })
      );
    } else {
      props.showMsgW(`Chassi inválido`);
    }
  };

  const changeChassi = (event) => {
    if (event.target.value.length <= 17) {
      setChassi(event.target.value);
    }
  };

  const [openNew, setOpenNew] = React.useState(false);
  const modalOpenNew = () => {
    setOpenNew(true);
  };
  const modalCloseNew = () => {
    setOpenNew(false);
  };

  const sendCpf = () => {
    setCpfResponse("localizado");
    setCpfData("2011-10-05T14:48:00.000Z");

    //if (Utils.validaCPF(cpf)) {
    trackPromise(
      ApiServices.apiGet(`valeCombustivel/statusCPF?cpf=${Mask.unMask(cpf)}`)
        .then((res) => {
          if (res.success) {
            if (res.data.statusCodido === "1") {
              setCpfResponse("naoLocalizado");
            } else if (res.data.statusCodido === "2") {
              setCpfResponse("localizado");
            } else if (res.data.statusCodido === "3") {
              setCpfResponse("disponivel");
            }
            setCpfData(res.data.dataAtualizacao);
            modalOpenCpf();
          } else {
            props.showMsgW(res.errors);
          }
        })
        .catch((res) => {
          //alert(JSON.stringify(res));
          props.showMsgW(`Erro ao buscar cpf`);
        })
    );
    //} else { props.showMsgW(`CPF inválido`) }
  };

  // const downloadReg = () => {
  //     saveAs(fileRegulamento, 'regulamento-vale-combustivel.pdf')
  // }

  useEffect(() => {
    modalOpenNew();
  }, []);

  function handleVerify(valor) {
   
    props.handleVerify(valor);
  }

  return (
    <>
      <GoogleReCaptcha onVerify={handleVerify} />
      {/* <Banner imgTitulo={bannerTitle} imgTituloMd={bannerTitleMd} imgMain={bannerImg} igmBg={bannerBg} type={'phone'} />  */}

      <div className="bgMainFeature">
        {/* <a href={fileRegulamento} target="_blank" rel="noopener noreferrer" ><img src={bannerSiglePage} alt="banner" className="d-none d-md-block d-lg-block responsive mAuto" /></a>
        <a href={fileRegulamento} target="_blank" rel="noopener noreferrer" ><img src={bannerSiglePageMobile} alt="banner" className="d-block d-md-none d-lg-none responsive mAuto" /></a> */}

        <a href="/como-funciona" target="_blank" rel="noopener noreferrer">
          <img
            src={BannerInicial}
            alt="banner"
            className="d-none d-md-block d-lg-block responsive mAuto"
            
          />
        </a>
      </div>

      <div className="container contentDefault">
        <div className="row">
          <div className="col-12">
            <h2>
              Promoção "Black Friday Chevrolet"
            </h2>
          </div>
          <div className="col-12">
            <p className="big">
            Realize um Serviço dentro da Rede de Concessionárias Chevrolet e concorra a um vale combustível Shell Box no valor de R$ 250,00 reais.
            </p>
            <p className="text-justify big">
            Promoção exclusiva para os clientes que realizarem esses serviços a partir de 08 de Novembro de 2022 até 28 de Novembro de 2022, seguindo os critérios de elegibilidade e se cadastrarem na campanha, conforme detalhado no Regulamento e FAQ disponíveis. 
            </p>
            <p className="big">Clique em “Saiba Mais” para saber como participar!</p>
            <div className="col-md-12 text-center ">
              <div className="mt30">
                <Link to="como-funciona">
                  <button style={{width: '300px'}} className="btn btn-primary btn-lg">
                   COMO FUNCIONA
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className='col-12'>
            <h2><i>Como participar?</i></h2>
            <p>
              <strong>1. </strong>
              Verifique abaixo se seu veículo está envolvido no Recall de Airbags Takata.
            </p>
            <p>
              <strong>2. </strong>
              Vá até uma Concessionária Chevrolet e realize o Recall de Airbags Takata.
              A Concessionária fará o registro do participante e a solicitação do crédito de R$ 500,00 reais via catálogo de prêmios.
            </p>
            <p>
              <strong>3. </strong>
              Após o processamento e validação da documentação, o crédito será liberado ao participante, conforme registro feito pela Concessionária.
            </p>
            <p>
              <strong>4. </strong>
              Você receberá um e-mail com a confirmação do cadastro e instruções para o recebimento do crédito e acesso ao catálogo.
              Caso não tenha recebido o e-mail e já fez o Recall no período da promoção, consulte a Concessionária ou nosso SAC.
            </p>
            <p>
              <strong>5. </strong>
              <a href="https://www.shell.com.br/motoristas/promocoes-e-campanhas/app-shell-box.html" target="_blank" rel="noopener noreferrer" ><strong>Baixe o app Shell Box</strong></a>, faça seu 
                            cadastro conforme dados do participante registrado pela Concessionária 
                            Chevrolet, <strong><a href="https://www.shell.com.br/motoristas/localizador-de-postos.html#iframe=Lz9sb2NhbGU9cHRfQlIjL0AtMTQuMjM3NTUsLTc1LjQ5OTgsNHo" target="_blank" rel="noopener noreferrer">consulte o posto credenciado</a></strong> mais perto 
                            para você e utilize seus créditos.
              Acesse o link do catálogo enviado para seu e-mail junto com a orientação para acesso e resgate seus <strong>R$500,00 reais</strong> da forma que preferir.
              Seja em vale combustível ou em qualquer outro produto/serviço disponível dentro do catálogo, são diversas opções!
            </p>
          </div>  
          <div className="container contentDefault">
            <div className="row">
              <div className="col-12">
                <h2>
                  Quer saber se seu veículo está envolvido no Recall de Airbags
                  Takata?
                </h2>
              </div>
              <div className="col-md-8">
                <div>
                  <h2>Digite o número do chassi:</h2>
                  <p>
                    Para encontrar o número do seu chassi, verifique a documentação
                    do seu veículo ou procure no canto inferior do vidro dianteiro
                    do passageiro. O chassi é composto por 17 caracteres, entre
                    números e letras.
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-md-7">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Digite o número do chassi"
                    value={chassi}
                    onChange={changeChassi}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-info btn-lg"
                      type="button"
                      onClick={sendChassi}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 mt30'>
            <h3>Já fez o Recall? Consulte se você já foi cadastrado:</h3>
          </div>
          <div className='col-md-7 mt30'>

            <div className="input-group">
              <input type="text" className="form-control form-control-lg"
                placeholder="Digite seu CPF"
                value={cpf} onChange={changeCPF}
              />
              <div className="input-group-append">
                <button className="btn btn-info btn-lg" type="button" onClick={sendCpf}>OK</button>
              </div>
            </div>
          </div>
          <div className='col-12 mt30 text-center'>
            <Regulamento />
          </div>*/}
        </div>
      </div>

      {/* <CallAction>
        <Link to='/faq'>
          <div className="flex vertAlignFlex justifyCenter">
            <div>
              <HelpOutline />
              FAQ CAMPANHA SEU CHEVROLET SEGURO
            </div> */}
      {/* <img src={boxShell} className="shellBoxIcon" alt="box Shell" /> */}
      {/* </div>
        </Link>
      </CallAction> */}

      {/* <Dialog
                open={openCpf}
                maxWidth={'md'}
                onClose={modalCloseCpf}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="volante"
            >
                <DialogTitle id="alert-dialog-title" >
                    <IconButton aria-label="close" onClick={modalCloseCpf}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <ModalCpf
                    status={cpfResponse}
                    date={cpfData}
                />
            </Dialog> */}

      {/* <Dialog
                open={open}
                maxWidth={"sm"}
                onClose={modalCloseRecall}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseRecall}>
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <Recall
                type={chassiResponse}
                chassi={chassi}
                date={chassiData}
                servico={dataServico}
                />
            </Dialog> */}

      {/* <Dialog
                open={openNew}
                maxWidth={"sm"}
                onClose={modalCloseNew}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseNew}>
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <div>
                <DialogTitle id="alert-dialog-title">ATENÇÃO!</DialogTitle>
                <DialogContent>
                A CHEVROLET E SUA REDE DE CONCESSIONÁRIAS NÃO SOLICITAM O ENVIO DE DOCUMENTOS PARA ESTA PROMOÇÃO ATRAVÉS DE WHATSAPP.
                </DialogContent>
                </div>
            </Dialog> */}
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValeCombustivel);
