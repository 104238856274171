// ##############################
// // // variaveis de configuração do sistema
// #############################

//const APICORE = "http://localhost:54017/api/v1/";
//RECAPTCHA VLD
// const RECAPTCHAKEY = "6LdDhDQeAAAAABpc_RDWQOPBWuJMqTDB-99kQ5qX";
// RECAPTCHA PRD

//const BASESITE = "http://localhost:3001";
//const BASESITE = "https://gmrecall-portal-stage.azurewebsites.net";

//const APICORE = "https://gmpremiado-api-prd.azurewebsites.net/api/v1/";

//const APICORE = "https://gmpremiado-api-prd.azurewebsites.net/api/v1/";
//const APICORE = "https://api.seuchevroletseguro.com.br/api/v1/";

//const PORTALROLETA = "https://play.servicopremiado.com.br/#/autenticarParticipante/"



// const PORTALROLETA = "https://gmpremiadoroleta-portal-stage.azurewebsites.net/#/autenticarParticipante/"

// const RECAPTCHAKEY ="6Lc59ewgAAAAAElU__BsC2BxEE6XZs_AHpbYjDJP";
// const APICORE = "https://gmpremiado-api-stage.azurewebsites.net/api/v1/";
// const URLBASEROLETA = "https://gmpremiadoroleta-api-stage.azurewebsites.net/"
 

const RECAPTCHAKEY ="6LeFLcsiAAAAAMMmE4pyW9gLRUvrNcmX_5TMS5q8";
const URLBASEROLETA = "https://gmblackfridaypremiado-api-roleta-stage.azurewebsites.net/"
const APICORE = "https://gmblackfridaypremiado-api-stage.azurewebsites.net/api/v1/";


export {
    APICORE,
    // PORTALROLETA,
    URLBASEROLETA,
    RECAPTCHAKEY//,
    //BASESITE
};