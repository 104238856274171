import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import RegulamentoConteudo from "./Texto/RegulamentoConteudo";
import RegulamentoConteudoSorteio from "./Texto/RegulamentoConteudoSorteio";

import download from "../../assets/img/icons/download.png";

const Regulamento = (props) => {
    const [open, setOpen] = React.useState(false);
    const modalOpen = () => {
        setOpen(true);
    };
    const modalClose = () => {
        setOpen(false);
    };

    return (
        <>
            <>
                <strong style={{ cursor: "pointer" }} onClick={modalOpen}>
                    {props.textoBtn
                        ? props.textoBtn
                        : " Regulamento, Termos e Condições da Campanha Vale Combustível"}
                </strong>
            </>

            {/* //COPIA ABAIXO */}

            {/* {props.styleBtn === 'link' ?
                <>
                    <strong style={{cursor:'pointer'}} onClick={modalOpen}>
                        {props.textoBtn ? props.textoBtn : 'regulamento' }
                    </strong>
                </>
                :
                <button className={`btn ${props.styleBtn === "container" ? 'btn-primary' : 'btn-link'}`} onClick={modalOpen}>
                    {props.styleBtn  !== "container" &&  <img src={download} alt="icon donwload" /> }
                    <strong style={{fontSize: '20px', fontWeight: 'bold'}}>
                        {props.textoBtn ? props.textoBtn : 'Visualize e baixe o regulamento' }
                    </strong>
                </button>
            } */}

            <Dialog
                open={open}
                maxWidth={"md"}
                onClose={modalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton aria-label="close" onClick={modalClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* {props.origem === "sorteio" ? (
                        <RegulamentoConteudoSorteio />
                    ) : (
                        )} */}
                        <RegulamentoConteudo />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Regulamento;
