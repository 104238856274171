import React, { Fragment } from "react";
import "./header.scss";

import { Link } from "react-router-dom";

import MenuNav from "../Menu/Menu";

// import logoCompany from '../../assets/img/logoCompany.png';
import logoCompany from "../../assets/black/logoHeader.png";
 
 

const Header = () => {




 function showSettings (event) {
    event.preventDefault();
     
    
  }





  return (
    <header>
     
      <div className="container">
        <div className="row">
          <div className="col-sm-12 header ">
            <Fragment>
              <Link to="/">
                <img
                  className="logoCompany"
                  src={logoCompany}
                  alt="Chevrolet"
                />
              </Link>
            </Fragment>

 



            <MenuNav />
          </div>
        </div>
      </div>




    </header>
  );
};

export default Header;
