import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TableHeader from "../../components/Table/TableHeader";
import TablePagination from "../../components/Table/TablePagination";
import ApiServices from "../../services/apiServices";
import { trackPromise } from "react-promise-tracker";
import Storage from "../../services/storage";
import styles from "./styles.module.scss";
import lupa from "../../assets/img/icons/lupa.png";
import editarNF from "../../assets/img/icons/editarNF.svg";
import olho from "../../assets/img/icons/ver.png";
const tabelaColunas = [
  "Número da NF",
  "Data da Emissão",
  "CNPJ do Prestador",
  "Razão social do Prestador",
  "Valor da NF",
  "Status",
  "Pontos",
  "",
];

function NotasFiscais(props) {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [pesquisa, setPesquisa] = useState("");

  const [notasFiscais, setNotasFiscais] = useState({
    page: 1,
    per_page: 5,
    rows: [],
    pesquisa: "",
    total: 0,
    total_pages: 0,
  });

  const ListarNotasFiscais = () => {
    ApiServices.apiPostWithTokenNotaFiscal(
      "notasfiscais/todas/portal",
      page,
      5,
      pesquisa
    ).then((data) => {
      if (data.success == true) {
        setNotasFiscais(data.data);
      }
    });
  };

  function msg(tipo, text) {
    //S W E
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }

  const gotoDet = (id) => {
    history.push(`/notas-fiscais/detalhes/${id}`);
  };

  const changePage = () => {
    if (perPage < notasFiscais.total) {
      ApiServices.apiPostWithTokenNotaFiscal(
        "notasfiscais/todas/portal",
        page,
        perPage + 5,
        pesquisa
      ).then((data) => {
        if (data.success == true) {
          setPerPage(perPage + 5);
          setNotasFiscais(data.data);
        }
      });
    }
  };
  const changePagePesquisar = async () => {
    if (pesquisa !== "") {
      var perPagePesquisa = notasFiscais.total;

      ApiServices.apiPostWithTokenNotaFiscal(
        "notasfiscais/todas/portal",
        page,
        perPagePesquisa,
        pesquisa
      ).then((data) => {
        if (data.success == true) {
          setPerPage(perPage + 5);
          setNotasFiscais(data.data);
        }
      });
    } else {
      ListarNotasFiscais();
    }
  };

  useEffect(() => {
    ListarNotasFiscais();
  }, []);

  return (
    <Fragment>
      <br />
      <div className="container text-center ">
        <h2>
          Minhas <strong>Notas Fiscais</strong>
        </h2>
        <h3>Cadastre sua nota fiscal.</h3>
        <h6>Após cadastrar, aguarde até a nota ser aprovada ou reprovada.</h6>
        <h6>Você receberá um e-mail informando o status final da nota.</h6>
      </div>
      <br />
      <div>
        {/* <div className="bgFeature3"> 
        <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div>
                  <h4 className={styles.tamanhoFontePontosTitulo}>
                    Total de pontos cadastrados
                  </h4>
                  <div className={styles.horizontal}></div>
                  <div className={styles.tamanhoFonteNumero}>5.000</div>
                </div>
              </div>
              <div className="col-md-3">
                <div>
                  <h4 className={styles.tamanhoFontePontosTitulo}>
                    Total de pontos ganhos
                  </h4>
                  <div className={styles.horizontal}></div>
                  <div className={styles.tamanhoFonteNumero}>4.500</div>
                </div>
              </div>
              <div className="col-md-2">
                <div>
                  <h4 className={styles.tamanhoFontePontosTitulo}>
                    Total reporvado
                  </h4>
                  <div className={styles.horizontal}></div>
                  <div className={styles.tamanhoFonteNumero}>0</div>
                </div>
              </div>
              <div className="col-md-2">
                <div>
                  <h4 className={styles.tamanhoFontePontosTitulo}>
                    Em análise
                  </h4>
                  <div className={styles.horizontal}></div>
                  <div className={styles.tamanhoFonteNumero}>500</div>
                </div>
              </div>
              <div className="col-md-1">
                <div>
                  <h4 className={styles.tamanhoFontePontosTitulo}>Utilizado</h4>
                  <div className={styles.horizontal}></div>
                  <div className={styles.tamanhoFonteNumero}>2.500</div>
                </div>
              </div>
            </div>
          </div> 
       </div> */}
        <div className="container contentDefault">
          {/* <div className="row">
            <div className="col-12 text-center">
              <Link to="/cadastro-notas-fiscais">
                <button  className="col-md-4 col-sm-12 btn py-3 btn-primary my-5 botao">
                  CADASTRAR NOTA FISCAL
                </button>
              </Link>
            </div>
          </div> */}
          <div className="container my-4 col-md-4 col-sm-12 d-flex flex-column align-items-center p-0 position-relative mt30">
            <input
              className="form-control "
              type="text"
              id="pesquisar"
              value={pesquisa}
              placeholder="PESQUISAR"
              onChange={(e) => {
                setPesquisa(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  changePagePesquisar();

                  e.preventDefault();
                }
              }}
            />

            <img
              onClick={() => changePagePesquisar()}
              className="lupaPesquisarNF"
              src={lupa}
              alt="icone lupa"
            />
          </div>
          <br />

          <br />
          <div className="col-12">
            <div className="table-responsive">
              <table className="table">
                <TableHeader columns={tabelaColunas} />
                <tbody>
                  {notasFiscais?.rows === 0 && (
                    <tr>
                      <td colSpan="7"> Sem Registros </td>
                    </tr>
                  )}

                  {notasFiscais?.rows &&
                    notasFiscais?.rows?.map((notaFiscal) => (
                      <tr key={notaFiscal.id}>
                        <td> {notaFiscal.numeroNF} </td>
                        <td> {notaFiscal.dataEmissaoNF} </td>
                        <td>{notaFiscal.cnpjPrestador} </td>
                        <td>{notaFiscal.razaoSocialPrestador} </td>
                        <td>{notaFiscal.valorTotalNF} </td>
                        <td>{notaFiscal.status} </td>
                        <td>
                          {" "}
                          {notaFiscal.pontos === null
                            ? "0"
                            : notaFiscal.pontos}{" "}
                        </td>

                        <td className="text-right">
                          <img
                            className="btn-edit-icon btn btn-icon fa fa-edit p-0"
                            src={olho}
                            width="30px"
                            onClick={() => gotoDet(notaFiscal.id)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <TablePagination data={notasFiscais} changePage={changePage} /> */}
            </div>
            <div className=" text-center">
              <button
                className="col-md-3 col-sm-12 btn py-3 btn-outline-primary my-4"
                onClick={() => changePage()}
              >
                VER MAIS
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect()(NotasFiscais);
