import React from 'react';
import './styles.scss';

const TableHeader = ({columns}) => {
  return (
    <thead>
        <tr>
            {
            columns.length > 0 &&
            columns.map((coluna, index) => (
                <th key={index}> {coluna} </th>
            ))
            }
        </tr>
    </thead>
  )
}

export default TableHeader;