import React  from 'react';
import './footer.scss';

import { Link } from 'react-router-dom';

import facebook from '../../assets/img/icons/facebook.png';
import youtube from '../../assets/img/icons/youtube.png';
//import googleplus from '../../assets/img/icons/googleplus.png';
import twitter from '../../assets/img/icons/twitter.png';
import instagram from '../../assets/img/icons/instagram.png';
import ibama from '../../assets/img/icons/selo_ibama.png';

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="row"> 
                    <div className="col-12">
                        <h3><strong>Siga a Chevrolet</strong></h3>
                        <div className="footerSocialMedia">
                            <a href="https://pt-br.facebook.com/chevroletbrasil/" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="Chevrolet" />
                            </a>
                            <a href="https://twitter.com/chevroletbrasil" target="_blank" rel="noopener noreferrer">
                                <img src={twitter} alt="Chevrolet" />
                            </a>
                            <a href="https://www.youtube.com/chevroletbrasil" target="_blank" rel="noopener noreferrer">
                                <img src={youtube} alt="Chevrolet" />
                            </a>
                            <a href="https://www.instagram.com/chevroletbr/" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="Chevrolet" />
                            </a>
                            {/* <a href="/" target="_blank" rel="noopener noreferrer">
                                <img src={googleplus} alt="Chevrolet" />
                            </a> */}
                        </div>
                    </div>
                    
                    <div className="col-12 footerContent">
                        <img src={ibama} alt="ibama" />
                        <p>

                        Faça revisões em seu veículo regularmente. Estes veículos estão em conformidade com o Programa de Controle da Poluição do Ar por Veículos Automotores - PROCONVE. JUNTOS SALVAMOS VIDAS. Campanha Black Friday Premiada Chevrolet: Realize “Serviços” em nossa Rede de Concessionárias Chevrolet durante o período de 08.11.2022 a 28.11.2022, se cadastre durante o período de participação de 08.11.2022 a 30.11.2022 e concorra a um vale combustível conforme os termos da campanha. Consulte o regulamento completo com todas as condições de participação e elegibilidade em <a href='https://www.chevroletpremiada.com.br' target="_blank" rel="noopener noreferrer">www.chevroletpremiada.com.br</a>. CAMPANHA AUTORIZADA ATRAVÉS DO CERTIFICADO DE AUTORIZAÇÃO SEAE/ME Nº 05.023826/2022. Imagens ilustrativas.

                        
                            {/* Faça revisões em seu veículo regularmente. 
                            Estes veículos estão em conformidade com o Programa de Controle da Poluição do Ar por Veículos Automotores - 
                            PROCONVE. NO TRÂNSITO, SUA RESPONSABILIDADE SALVA VIDAS. 
                            Campanha Seu Chevrolet Seguro: Faça o Recall do Airbag Takata durante o período de 01.04.2022 a 30.09.2022 e receba um crédito a ser utilizado 
                            exclusivamente dentro do Catálogo de Prêmios Chevrolet para proprietários ou condutores dos modelos 
                            Celta (2013 – 2016) e Classic (2012 – 2016) previstos conforme os termos no regulamento da campanha. 
                            Consulte regulamento completo com todas as condições de participação e 
                            elegibilidade em <a href="https://www.seuchevroletseguro.com.br" target="_blank" rel="noopener noreferrer">www.seuchevroletseguro.com.br </a>.  Imagens ilustrativas. */}
                        </p>
                    </div>

                    <div className="col-12">
                        <ul>
                            <li>
                                Copyright Chevrolet
                            </li>
                            <li>
                                <a href="https://www.chevrolet.com/worldwide-websites" target="_blank" rel="noopener noreferrer">Chevrolet Wolrdwide</a>
                            </li>
                            <li>
                                <Link to='/politica-de-privacidade'>Informações legais</Link>
                            </li>
                        </ul> 
                    </div>
                    
                </div>
               
            </div>
        </footer>
    )
}

export default Footer;