import React from 'react';
import './loadingBar.scss';

import { usePromiseTracker } from "react-promise-tracker";
import LinearProgress from '@material-ui/core/LinearProgress';

const LoadingBar = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div className="loadPage"><LinearProgress /></div>
    );
}

export default LoadingBar;