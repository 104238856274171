import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import "./styles.cadastro.scss";
import { useEffect } from "react";
import ApiServices from "../../services/apiServices";

import CardActionArea from "@material-ui/core/CardActionArea";
import Mask from "../../services/mask";
import CardContent from "@material-ui/core/CardContent";
import Utils from "../../services/serviceUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import imgNf from "../../assets/img/numeroNF.png";
import ValorTotal from "../../assets/img/valor01.png";
import imgEmissao from "../../assets/img/dataEmissao.png";
import imgCnpj from "../../assets/img/Cnpj.png";
import imgRazao from "../../assets/img/razaoSocial.png";
import imgInfo from "../../assets/img/info.png";
import * as moment from "moment";
import EmptyImage from "../../assets/img/emptyImage.PNG";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import iconeAnexar from "../../assets/img/icons/iconeAnexar.png";
import iconeArquivo from "../../assets/img/icons/iconeArquivo.png";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";

import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";

import olho from "../../assets/img/eye.svg";

function CadastroNotasFiscais(props) {
  const anexarPdf = useRef(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [verPdf, setVerPdf] = useState(false);

  const [liberarBtSalvar, setLiberarBtSalvar] = useState(false);
  const history = useHistory();
  const [imageUploaded, setImageUploaded] = useState(0);
  const [OpenNumeroNf, setOpenNumeroNf] = React.useState(false);
  const [file, setFile] = useState(null);
  //const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [mainState, setMainState] = useState("initial"); // initial, uploaded 
  const modalOpenNumeroNf = () => {
    setOpenNumeroNf(true);
  };
  const modalCloseNumeroNf = () => {
    setOpenNumeroNf(false);
  };

  const [OpenDataEmissao, setOpenDataEmissao] = React.useState(false);
  const modalOpenDataEmissao = () => {
    setOpenDataEmissao(true);
  };
  const modalCloseDataEmissao = () => {
    setOpenDataEmissao(false);
  };

  const [OpenCnpj, setOpenCnpj] = React.useState(false);
  const modalOpenCpnj = () => {
    setOpenCnpj(true);
  };
  const modalCloseCnpj = () => {
    setOpenCnpj(false);
  };

  const [OpenRazao, setOpenRazao] = React.useState(false);
  const modalOpenRazao = () => {
    setOpenRazao(true);
  };
  const modalCloseRazao = () => {
    setOpenRazao(false);
  };

  const [alertaCampo, setAlertaCampo] = useState({
    numeroNF: {
      status: "d-none",
      alerta: "Número Nf inválido",
    },
    dataEmissaoNF: {
      status: "d-none",
      alerta: "Data emissão NF inválido",
    },
    cnpjPrestador: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
    razaoSocialPrestador: {
      status: "d-none",
      alerta: "Data inválido",
    },
    valorTotalNF: {
      status: "d-none",
      alerta: "Valor total NF invalido",
    },
  });

  const [notaFical, setNotaFical] = useState({
    numeroNF: "",
    dataEmissaoNF: "",
    cnpjPrestador: "",
    razaoSocialPrestador: "",
    valorTotalNF: "",
    urlDocumentoNF: "",
  });

  const [open, setOpen] = React.useState(false);

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  function fnBtSalvar(e) {
    setLiberarBtSalvar(true)
    const castroNotaFiscal = {
      numeroNF: notaFical.numeroNF,
      dataEmissaoNF: moment(notaFical.dataEmissaoNF, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      cnpjPrestador: Mask.unMask(notaFical.cnpjPrestador),
      razaoSocialPrestador: notaFical.razaoSocialPrestador,
      valorTotalNF: notaFical.valorTotalNF
        .replaceAll(".", "")
        .replaceAll(",", "."),
      urlDocumentoNF: notaFical.urlDocumentoNF,
      status: 1,
    };

    ApiServices.apiPostWithToken("notasfiscais", castroNotaFiscal).then(
      (data) => {
        if (data.success === true) {
          msg("s", "Cadastro realizado com sucesso!");
          history.push("notas-fiscais");
        } else {
          setLiberarBtSalvar(false)
          msg("w", data.errors[0]);
        }
      }
    );
  }

  const showEmptyImageDesktop = () => {
    if (imageUploaded == 0 && notaFical.numeroNF == "") {
      return (
        <div className="d-flex justify-content-center empty-image">
          <img className="image" src={EmptyImage} />
        </div>
      );
    }
  };

  function msg(tipo, text) {
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }

  const allowedFiles = ["application/pdf"];

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile && allowedFiles.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setPdfFile(e.target.result);
        };

        setMainState("uploaded");
        //setSelectedFile(e.target.files[0]);
        setImageUploaded(1);
        // setFile(e.target.files[0]);

        const documentoNotaFiscal = new FormData();
        documentoNotaFiscal.append("files", e.target.files[0]);

        ApiServices.apiUpload(
          "genericos/enviar/notafiscal",
          documentoNotaFiscal
        ).then((data) => {
          if (data.success === true) {
            setNotaFical((prev) => ({
              ...prev,
              urlDocumentoNF: data.data.filesUploaded[0],
            }));
          } else {
            msg("w", data.errors[0]);
          }
        });
      } else {
        msg("w", "Por favor, selecionar um arquivo no formtado PDF.");
        setPdfFile("");
        setFile("");
      }
    } else {
      console.log("please select a PDF");
    }
  };

  //aqui
  const handleUploadClick = (event) => {
    setFile(event.target.files[0]);
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      //setSelectedFile([reader.result]);
    };

    setMainState("uploaded");
    //setSelectedFile(event.target.files[0]);
    setImageUploaded(1);
  };

  function fnVerPdf() {
    if (verPdf == false) {
      setVerPdf(true);
    } else {
      setVerPdf(false);
    }
  }

  return (
    <>
      <div className="container contentDefault">
        <div className="col-12">
          <br />
          <div className="titulo">
            <span>
              Cadastro de <strong>Notas Fiscais </strong>
            </span>
            <p>Notas aceitas a partir de 08/11/2022.
              <br />
              Por gentileza, enviar o PDF original (eletrônico) e não escaneado. Imagens podem ser reprovadas.

              <br />
              Confira se todas as informações estão de acordo com a NF, especialmente o número da nota e valor total da nota.
              <br />

              Recibos e cupons fiscais não serão aceitos. Somente nota fiscal.</p>
          </div>
          <br />

          <p className="p-1">
            <span className="span-1">Dados da Nota Fiscal</span> <br />
            <span className="span-2">
              Só serão aceitas Notas Fiscais que estejam em nome do participante
            </span>
          </p>
          <Dialog
            open={OpenNumeroNf}
            maxWidth={"lg"}
            onClose={modalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <IconButton aria-label="close" onClick={modalCloseNumeroNf}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <img src={imgNf} />
            </DialogContent>
          </Dialog>

          <div className="row">
            <div className=" col-sm-12 col-md-4 form-outline mb-3">
              <label className="form-label m0">Número da NF*</label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpenNumeroNf}>
                {props.iconBtn ? (
                  props.iconBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>

              <input
                className="form-control"
                type="text"
                id="numeroNF"
                placeholder="Insira o número da Nota Fiscal"
                value={notaFical.numeroNF}
                onChange={(e) => {
                  alertaCampo.numeroNF.status = "d-none";
                  e.persist();
                  setNotaFical((prev) => ({
                    ...prev,
                    numeroNF: e.target.value.replace(/[^0-9]/g, ""),
                  }));
                }}
              />

              <p className={alertaCampo.numeroNF.status}>
                <em>{alertaCampo.numeroNF.alerta}</em>
              </p>
            </div>
            <Dialog
              open={OpenDataEmissao}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseDataEmissao}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={imgEmissao} />
              </DialogContent>
            </Dialog>

            <div className=" col-sm-12 col-md-3 form-outline mb-3">
              <label className="form-label m0">Data de emissão*</label>
              <strong
                style={{ cursor: "pointer" }}
                onClick={modalOpenDataEmissao}
              >
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="data"
                placeholder="dd/mm/aaaa"
                value={notaFical.dataEmissaoNF}
                onChange={(e) => {
                  alertaCampo.dataEmissaoNF.status = "d-none";
                  e.persist();
                  setNotaFical((prev) => ({
                    ...prev,
                    dataEmissaoNF: Mask.date(e.target.value),
                  }));
                }}
              />
              <p className={alertaCampo.dataEmissaoNF.status}>
                <em>{alertaCampo.dataEmissaoNF.alerta}</em>
              </p>
            </div>
            <Dialog
              open={OpenCnpj}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseCnpj}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={imgCnpj} />
              </DialogContent>
            </Dialog>

            <div className="col-sm-12 col-md-5 form-outline mb-3">
              <label className="form-label m0">CNPJ do prestador*</label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpenCpnj}>
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="cnpj"
                placeholder="00.000.000/0000-00"
                value={Mask.cnpj(notaFical.cnpjPrestador)}
                onChange={(e) => {
                  alertaCampo.cnpjPrestador.status = "d-none";
                  e.persist();
                  setNotaFical((prev) => ({
                    ...prev,
                    cnpjPrestador: Mask.cnpj(e.target.value),
                  }));
                }}
              />
              <p className={alertaCampo.cnpjPrestador.status}>
                <em>{alertaCampo.cnpjPrestador.alerta}</em>
              </p>
            </div>
            <Dialog
              open={OpenRazao}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseRazao}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={imgRazao} />
              </DialogContent>
            </Dialog>

            <div className="col-sm-12 col-md-8 form-outline mb-3">
              <label className="form-label m0">
                Razão social do prestador*
              </label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpenRazao}>
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="razaoSocial"
                placeholder=""
                value={notaFical.razaoSocialPrestador}
                onChange={(e) => {
                  alertaCampo.razaoSocialPrestador.status = "d-none";
                  e.persist();
                  setNotaFical((prev) => ({
                    ...prev,
                    razaoSocialPrestador: e.target.value,
                  }));
                }}
              />
              <p className={alertaCampo.razaoSocialPrestador.status}>
                <em>{alertaCampo.razaoSocialPrestador.alerta}</em>
              </p>
            </div>
            <Dialog
              open={open}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={ValorTotal} />
              </DialogContent>
            </Dialog>

            <div className="col-sm-12 col-md-4 form-outline mb-3">
              <label className="form-label m0">Valor total da NF*</label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpen}>
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="valorTotal"
                placeholder="R$ 0.000,00"
                value={Mask.valor(notaFical.valorTotalNF)}
                onChange={(e) => {
                  alertaCampo.valorTotalNF.status = "d-none";
                  e.persist();
                  setNotaFical((prev) => ({
                    ...prev,
                    valorTotalNF: Mask.valor(e.target.value),
                  }));
                }}
              />
              <p className={alertaCampo.valorTotalNF.status}>
                <em>{alertaCampo.valorTotalNF.alerta}</em>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <p>
              <span className="span-4">Nota Fiscal</span>
              <br />
              <span className="span-5">
                Anexe aqui uma cópia da Nota Fiscal
              </span>
              <br />
              <span className="span-6">
                Serão aceitos somente arquivos em formato PDF - máx 5MB
              </span>
            </p>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            {/* <Grid container justify="center" alignItems="center">
                      <input
                          accept="image/*"
                          className="d-none"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(event) => handleUploadClick(event)}
                      />
                  </Grid>  */}

            <div>
              <input
                type="file"
                ref={anexarPdf}
                className="botaoAnexar d-none"
                accept="application/pdf"
                onChange={(e) => {
                  handleFile(e);
                }}
              ></input>

              {!pdfFile && (
                <div
                  className="imgIconeAnexar "
                  onClick={() => {
                    anexarPdf.current.click();
                  }}
                >
                  <img className="mx-3" src={iconeAnexar} alt="icone anexar" />
                  {"CARREGAR ARQUIVO"}
                </div>
              )}

              {pdfFile && (
                <div
                  className="imgIconeAnexar "
                  onClick={() => {
                    fnVerPdf();
                  }}
                >
                  <img className="mx-3" src={iconeArquivo} alt="icone anexar" />

                  {verPdf ? "OCULTAR ARQUIVO" : "VISUALIZAR ARQUIVO"}
                </div>
              )}
            </div>

            {/* <div className="d-flex justify-content-center" >
                    <label htmlFor="contained-button-file">
                        <Fab component="span">
                            Incluir Foto
                        </Fab>
                    </label>
                  </div> */}
            {/* {showEmptyImageDesktop()} */}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            {/* <CardActionArea>
                      <img
                          width="100%"
                          src={selectedFile ?? notaFical?.urlDocumentoNF}
                      />
                  </CardActionArea> */}
            <div className="viewer">
              {verPdf && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfFile}
                    plugins={[defaultLayoutPluginInstance]}
                  ></Viewer>
                </Worker>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <button
              className="btn btn-secondary col-md-3 col-sm-12 m-3 p-3"
              onClick={() => history.push(`/notas-fiscais`)}
            >
              CANCELAR
            </button>

            <button
              className="btn btn-primary col-md-3 col-sm-12 m-3 p-3"
              disabled={liberarBtSalvar}
              onClick={(e) => {
                fnBtSalvar(e);
              }}
            >
              ENVIAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroNotasFiscais);
