import React, { Fragment, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import bannerComoFunciona from "../../assets/black/bannerComoFunciona.png";
import "./comoFunciona.scss"


import bannerImgSorteioV2 from "../../assets/img/banners/sorteio-004.png";
import bannerImgSorteioMobileV2 from "../../assets/img/banners/sorteio-004b.png";

function ComoFunciona(props) {
  return (
    <Fragment>
      {/* <Banner imgTitulo={bannerTitle} imgMain={bannerImg} igmBg={bannerBg} type={'car'} />  */}
      {/* <a target="_blank" rel="noopener noreferrer" ><img src={bannerImgSorteioV2} alt="banner" className="d-none d-md-block d-lg-block responsive mAuto" /></a>
                <a target="_blank" rel="noopener noreferrer" > <img src={bannerImgSorteioMobileV2} alt="banner" className="d-block d-md-none d-lg-none responsive mAuto" /></a> */}

      <div className="container contentDefault pb-4 text-center">
        <h1 className="col-12">Como funciona</h1>
      </div>

      <div className="bgMainFeature">
        <a target="_blank" rel="noopener noreferrer">
          <img
            src={bannerComoFunciona}
            alt="banner"
            className="d-none d-md-block d-lg-block responsive mAuto banner-home"
          />
        </a>
      </div>

      <div className="container contentDefault">
        <div className="row">


          <div className='col-12 mt30'>
       
              <p>
                   <div className='col-12'>
                        <h2 className="tituloComoFunciona">
                          <i>Promoção “Black Friday Premiada Chevrolet”</i>
                        </h2>
                        <h2 className='branco mt30'><i>Passo a Passo para participar da Campanha</i></h2>
                        <p>
                           1. 
                          Vá até uma Concessionária Chevrolet e realize o Serviço de sua preferência (conforme especificado em regulamento). 
                        </p>
                        <p>
                           2. 
                          Abra sua caixa de e-mails e salve o arquivo da NFe (Nota Fiscal Eletrônica) que a concessionária terá enviado para o e-mail disponibilizado no ato do Serviço (verifique sempre a caixa de spam/lixo eletrônico).
                        </p>
                        <p>
                           3. 
                          No hotsite da campanha <a href="https://www.chevroletpremiada.com.br" target="_blank" rel="noopener noreferrer">www.chevroletpremiada.com.br</a> realize seu cadastro, dê o “aceite” nas normas e regulamento da campanha e realize o upload da(s) sua(s) NFe(s) referente(s) ao(s) serviço(s) adquirido(s).  
                        </p>
                        <p>
                           4.	
                          Você receberá um e-mail confirmando sua participação na campanha.
                        </p>
                        <p>
                           5. 
                          Após a validação da(s) NF(s), você recebe sua(s) chance(s) na roleta em “Testar Minha Sorte”, conforme exemplificado na FAQ e Regulamento disponíveis (cada chance na roleta pode ser usada de acordo com a preferência do participante, durante a data da campanha).
                        </p>
                        <p>
                           6. 
                          Confira todos os detalhes na FAQ e Regulamento da campanha. 
                        </p>
                        <p>
                           7.
                          Após contemplado, você terá a visualização dentro do ambiente logado no site e receberá todas as informações por e-mail (o passo a passo para uso do vale combustível está detalhado abaixo).
                        </p>
                        <p>
                           8.
                          Boa sorte!
                        </p>
                    </div> 
                    <div className='col-12 my-5'>
                        <h2 className='branco'><i>Passo a Passo para usar seu Vale Combustível</i></h2>
                        <p>
                           1. 
                          Baixe o APP da Shell Box, caso ainda não tenha feito.
                        </p>
                        <p>
                           2. 
                          Cadastre-se ou use login e senha para acessar o APP.
                        </p>
                        <p>
                           3. 
                          Certifique-se de que o CPF do cadastro no APP é o mesmo cadastrado na campanha.
                        </p>
                        <p>
                           4. 
                          Agora com o crédito no APP, vá até o posto credenciado mais próximo que tenha integração com o Shell Box e aproveite seu voucher.
                        </p>
                        <p className="text-justify mt30">
                          <strong>IMPORTANTE: </strong>
                          O prazo de validade para uso dos créditos é de 6 meses contados a partir da data do crédito. Caso não sejam usados, os créditos serão cancelados.
                          Os participantes deverão consultar os postos de combustível Shell Select participantes com pagamento disponível por meio do app Shell Box, listados no site <a href="https://www.shell.com.br/promocaoshell"  target="_blank" rel="noopener noreferrer">www.shell.com.br/promocaoshell</a> ou, estando no posto ou loja, confirmar se o estabelecimento participa desta campanha.
                          Fica esclarecido que é de responsabilidade do cliente a verificação da participação do posto ou loja onde pretende utilizar seu voucher.
                        </p>
                    </div> 
              </p>
          </div> 

          <div className="col-12">
          <div>
          </div>
            <div className="col-12 text-center ">
              <div className="mt30">
                <Link to="/cadastro">
                  <button className="btn btn-primary btn-lg">
                    FAZER CADASTRO
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect()(ComoFunciona);
