import React, { useEffect } from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";



const Default = (props) => {
    const pathname = () => {
        return window.location.pathname;
      };
    useEffect(() => {
      console.log(window.location.pathname);
    }, [window.location.pathname]);
  return (
    <div id="wrapper">
      {/* {pathname() !== "/teste-roleta" && (
        <div id="top">
          <Header></Header>
        </div>
      )} */}

      <div>{props.children}</div>

      {/* {pathname() !== "/teste-roleta" && (
        <div id="top">
          <Header></Header>
        </div>
      )} */}
    </div>
  );
};

export default Default;
