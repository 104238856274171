import React, { Component, Fragment } from 'react';

class Notfound extends Component {

    render() {
        return (
            <Fragment>
                <div>
                    Página não encontrada.
                </div>
            </Fragment>
        )
    }

}

export default Notfound;