import React, { Fragment, useEffect, useState } from "react";
import "./menu.scss";

import { Link } from "react-router-dom";
import Storage from "../../services/storage";
import Logout from "../../views/logout/Logout";
import { slide as Menu } from 'react-burger-menu'

const MenuNav = () => {
  const [userToken, setUserToken] = useState(false);
  const [siglaOla, setSiglaOla] = useState("");

  function fnVerificarToken() {
    // if () { setUserToken(true) } else { setUserToken(false) }
  }

  function sairLogado() {
    setUserToken(false);
    window.location.reload();
  }

  useEffect(() => {
    if (Storage.getUser() !== null) {
      setUserToken(true);
      const arrNome = Storage.getUser().userClaims.nome.split(" ");
      if (arrNome.length <= 1) {
        setSiglaOla(arrNome[0].charAt(0) + arrNome[0].charAt(1));
      } else if (arrNome.length > 1) {
        setSiglaOla(arrNome[0].charAt(0) + arrNome[1].charAt(0));
      }

    }
  }, [userToken]);

  const pathname = () => {
    return window.location.pathname;
  };

 

  return (
    <Fragment>

 

      <div className={Storage.getUser() !== null ? "menuItens col-12 d-flex align-items-center menuDesktopLogado" : "menuItens col-12 d-flex align-items-center menuDesktopDeslogado"}>
        <ul>
          <li className={`${pathname() === "/" ? "active" : ""}`}>
            <Link to="/"> Início </Link>
          </li>

          <li className={`${pathname() === "/como-funciona" ? "active" : ""}`}>
            <Link to="/como-funciona"> Como funciona </Link>
          </li>

          <li
            className={`${pathname().includes("regulamento") ? "active" : ""}`}
          >
            <Link to="/regulamento"> Regulamento </Link>
          </li>

          <li className={`${pathname().includes("faq") ? "active" : ""}`}>
            <Link to="/faq"> Faq </Link>
          </li>
        
          <li className={`${pathname().includes("roleta") ? "active" : ""}`}>
            <Link to="/roleta" className="btn btn-primary"> Teste Roleta </Link>
          </li>

          {userToken && (
            <li
              className={`${pathname() === "/notas-fiscais" ? "active" : ""}`}
            >
              <Link to="/notas-fiscais"> Notas fiscais </Link>
            </li>
          )}

          {userToken && (
            <li
              className={`${pathname() === "/black-friday-premiada" ? "active" : ""
                }`}
            >
              <Link to="/black-friday-premiada"> Black Friday Premiada </Link>
            </li>
          )}
        </ul>

        <ul>
          {!userToken && (
            <li>
              <Link to="/login" className="btn btn-outline-primary branco py-2 px-4">
                Entrar
              </Link>
            </li>
          )}

          <div className="d-flex">
            {userToken && (
              <li>
                <a className="link">Olá, {siglaOla}</a>
              </li>
            )}

            {userToken && (
              <li className={`${pathname() === "/cadastro" ? "active" : ""}`}>
                <Link to="/cadastro"> Meus dados </Link>
              </li>
            )}

            {userToken && (
              <li
                className={`${pathname() === "/" ? "active" : ""}`}
                onClick={() => sairLogado()}
              >
                <Link to="/logout"> Sair </Link>
              </li>
            )}
          </div>
        </ul>

        {/* <li className={`${pathname() === '/vale-combustivel' ? 'active' : ''}`}>
            <Link  to='/vale-combustivel'> Vale-Combustível </Link>
          </li>  */}

        {/* <li className={`${pathname() === '/sorteio' ? 'active' : ''}`}>
            <Link to='/sorteio'>Sorteio</Link>
          </li> */}

      </div> 










      <div className={Storage.getUser() !== null ? "menuMobileExternoLogado" : "menuMobileExternoDeslogado"}>


       <Menu right >


          <div className="menuItens  d-flex  flex-column menuMobile left">
            <ul>
              {userToken && (
                <li>
                  <span className=" ">Olá, {siglaOla}</span>
                </li>
              )}
              <li className={`${pathname() === "/" ? "active" : ""}`}>
                <Link to="/"> Início </Link>
              </li>

              <li className={`${pathname() === "/como-funciona" ? "active" : ""}`}>
                <Link to="/como-funciona"> Como funciona </Link>
              </li>

              <li
                className={`${pathname().includes("regulamento") ? "active" : ""}`}
              >
                <Link to="/regulamento"> Regulamento </Link>
              </li>

              <li className={`${pathname().includes("faq") ? "active" : ""}`}>
                <Link to="/faq"> Faq </Link>
              </li>
              <li className={`${pathname().includes("roleta") ? "active" : ""}`}>
                <Link to="/roleta"> Teste Roleta </Link>
              </li>

              {userToken && (
                <li
                  className={`${pathname() === "/notas-fiscais" ? "active" : ""}`}
                >
                  <Link to="/notas-fiscais"> Notas fiscais </Link>
                </li>
              )}

              {userToken && (
                <li
                  className={`${pathname() === "/black-friday-premiada" ? "active" : ""
                    }`}
                >
                  <Link to="/black-friday-premiada"> Black Friday Premiada </Link>
                </li>
              )}
            </ul>

            <ul>
              {!userToken && (
                <li>
                  <Link to="/login" className="btn btn-outline-primary py-2 px-4">
                    Entrar
                  </Link>
                </li>
              )}



              {userToken && (
                <li className={`${pathname() === "/cadastro" ? "active" : ""}`}>
                  <Link to="/cadastro"> Meus dados </Link>
                </li>
              )}

              {userToken && (
                <li
                  className={`${pathname() === "/logout" ? "active" : ""}`}
                  onClick={() => sairLogado()}
                >
                  <Link to="/logout"> Sair </Link>
                </li>
              )}

            </ul>

            {/* <li className={`${pathname() === '/vale-combustivel' ? 'active' : ''}`}>
            <Link  to='/vale-combustivel'> Vale-Combustível </Link>
          </li>  */}

            {/* <li className={`${pathname() === '/sorteio' ? 'active' : ''}`}>
            <Link to='/sorteio'>Sorteio</Link>
          </li> */}
          </div>
        </Menu>

      </div>







    </Fragment>
  );
};

export default MenuNav;
