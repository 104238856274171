import React, { useEffect } from 'react';

/////////////////////////////
// redux 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMsgS, showMsgW, showMsgE } from '../../store/actions/snackbarActions.js';

import { trackPromise } from 'react-promise-tracker';
import ApiServices from '../../services/apiServices';

import './faq.scss';
import Faqs from '../../components/Faqs/Faqs';

import iconArrow from '../../assets/img/icons/arrow-L.png';

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha,
    withGoogleReCaptcha
  } from 'react-google-recaptcha-v3';

const FaqSorteio = props => {
    //Ordem, id, Texto, Titulo
    const [faqlist, setFaqlist] = React.useState([
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 1, 'id': 1, 'status': true, 'tipoFaq': 1,
            'Titulo': "O que é a campanha Seu Chevrolet Seguro – Sorteio de 3 (três) Onix 0km?",
            'Texto': "<p style='text-align: justify;'>A presente Campanha tem como objetivo a realização do maior número possível de serviços de recall nos veículos Chevrolet modelos Celta (ano de fabricação 2013 a 2016) e Classic (ano de fabricação 2012 a 2016) através da realização de 3 (três) sorteios de carro 0km para os clientes proprietários destes modelos que realizarem o serviço de recall conforme detalhado neste FAQ, desde o início dos atendimentos, em julho de 2020. </p>",
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 2, 'id': 2, 'status': true, 'tipoFaq': 1,
            'Titulo': "Qual a premiação da presente Campanha?",
            'Texto': "<p style='text-align: justify;'>A campanha vai premiar 3 proprietários dos modelos citados com 1 (um) Novo Onix LT Turbo, 0km e com todos os custos pagos pela Chevrolet (emplacamento, licenciamento, documentação, IPVA). </p>",
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 3, 'id': 3, 'status': true, 'tipoFaq': 1,
            'Titulo': "Quem pode participar?",
            'Texto': `<p style='text-align: justify;'>Poderão participar desta Campanha todos os proprietários 
            (pessoas físicas) dos veículos Chevrolet modelos Celta (ano de fabricação 2013 a 2016) e Classic 
            (ano de fabricação 2012 a 2016), que comprovadamente realizarem o serviço do Recall <strong>desde o início dos 
            atendimentos, em 29 de julho de 2020</strong>. O participante elegível será apenas o proprietário do veículo, 
            na data na qual foi realizado o serviço – a propriedade do veículo na data do serviço deverá ser comprovada 
            pelos sorteados após realizados os sorteios.</p>
            <ul>
                <li>Celta (MY 2013 a 2016): chassi de DG124288 até GG100849</li>
                <li>Classic (MY 2012 a 2016): chassi de CC174331 até GR160004</li>
            </ul>
            
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 4, 'id': 4, 'status': true, 'tipoFaq': 1,
            'Titulo': "Quem não pode participar?",
            'Texto': `<p style='text-align: justify;'>Não serão contemplados no sorteio qualquer outra pessoa que não seja o proprietário do veículo. Apenas o dono do veículo poderá concorrer ao sorteio, indiferente de quem levou à concessionaria para realização do serviço.  
                </p>
                <p style='text-align: justify;'>
                Caso o carro seja vendido e estiver na posse de outra pessoa no momento do sorteio, será contemplado o dono do veículo no momento da realização do serviço do Recall.
                </p>
                <p style='text-align: justify;'>
                Também não serão aceitos na campanha carros em nome de CNPJ. 
                </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 5, 'id': 5, 'status': true, 'tipoFaq': 1,
            'Titulo': "Onde ocorrerá a campanha? Qual a abrangência?",
            'Texto': `<p style='text-align: justify;'>Esta Campanha será realizada em todo o Território Nacional Brasileiro </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 6, 'id': 6, 'status': true, 'tipoFaq': 1,
            'Titulo': "Qual o período de participação?",
            'Texto': `<p style='text-align: justify;'>A presente Campanha terá seu período de participação de 16 de Abril de 2021 até 31 de Março de 2022.</p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 7, 'id': 7, 'status': true, 'tipoFaq': 1,
            'Titulo': "Qual o serviço realizado em concessionária que é válido para a campanha?",
            'Texto': `<p style='text-align: justify;'>O serviço é de substituição do airbag do lado do motorista conforme modelos citados, Celta (ano de fabricação 2013 a 2016) e Classic (ano de fabricação 2012 a 2016).</p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 8, 'id': 8, 'status': true, 'tipoFaq': 1,
            'Titulo': "Como posso participar da campanha?",
            'Texto': `<p style='text-align: justify;'>O participante deverá acessar o site da 
                campanha  <a href="https://www.seuchevroletseguro.com.br" target="_blank">www.seuchevroletseguro.com.br</a>, validar sua 
                participação dando o “aceite” nas normas e regulamento e 
                então receberá seu número da sorte para concorrer aos sorteios. Será enviado um e-mail de confirmação da participação.</p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 9, 'id': 9, 'status': true, 'tipoFaq': 1,
            'Titulo': "Como é gerado meu número da sorte?",
            'Texto': `<p style='text-align: justify;'>O número da sorte é gerado automaticamente no site 
            da campanha <a href="https://www.seuchevroletseguro.com.br" target="_blank">www.seuchevroletseguro.com.br</a>, depois do “aceite” na promoção.  </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 10, 'id': 10, 'status': true, 'tipoFaq': 1,
            'Titulo': "Quais serão as datas dos sorteios?",
            'Texto': `<p style='text-align: justify;'>Serão realizados três (03) sorteios, sendo estimado o primeiro sorteio em 28/07/2021, o segundo sorteio em 27/10/2021 e o último sorteio em 12/01/2022. Com as apurações em 30/07/2021, 29/10/2021 e 14/01/2022, respectivamente. </p>
            `,
        },

        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 11, 'id': 11, 'status': true, 'tipoFaq': 1,
            'Titulo': "Onde serão divulgados os resultados?",
            'Texto': `<p style='text-align: justify;'>Os resultados de cada sorteio serão divulgados na página principal da campanha, disponível em <a href="https://www.seuchevroletseguro.com.br" target="_blank">www.seuchevroletseguro.com.br</a>, no prazo de até 10 (dez) dias úteis da data do sorteio. </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 12, 'id': 12, 'status': true, 'tipoFaq': 1,
            'Titulo': "Ganhei o sorteio, e agora?",
            'Texto': `<p style='text-align: justify;'>A Chevrolet entrará em contato com cada ganhador para pegar as informações necessárias para realizar a premiação, além de pedir a comprovação sobre a propriedade do veículo na época do serviço, através dos documentos: CRLV – Certificado de Registo e Licenciamento de Veículo / DUT (documento único de transferência que acompanha o CRLV em caso de venda do carro) e documento pessoal do proprietário (CNH/CPF/RG). </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 13, 'id': 13, 'status': true, 'tipoFaq': 1,
            'Titulo': "Fazendo o serviço antes dos três sorteios, tenho três chances de ganhar?",
            'Texto': `<p style='text-align: justify;'>Sim. Quanto antes realizado o serviço, mais chances de ganhar!</p>
             <p style='text-align: justify;'>
                Porém fique atendo aos prazos de cada sorteio no regulamento, disponível na página inicial da campanha.
             </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 14, 'id': 14, 'status': true, 'tipoFaq': 1,
            'Titulo': "Se eu ganhei um dos sorteios, concorro nos demais?",
            'Texto': `<p style='text-align: justify;'>Não. Seu número da sorte sairá automaticamente do próximo sorteio.  </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 15, 'id': 15, 'status': true, 'tipoFaq': 1,
            'Titulo': "Se eu tiver mais de um chassi para concorrer, tenho mais de uma chance?",
            'Texto': `<p style='text-align: justify;'>Sim. Cada chassi/veículo equivale a um número da sorte.  </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 16, 'id': 16, 'status': true, 'tipoFaq': 1,
            'Titulo': "Onde posso fazer o acompanhamento da promoção?",
            'Texto': `<p style='text-align: justify;'>O acompanhamento da promoção deve ser feito no site da campanha, disponível em www.seuchevroletseguro.com.br.  </p>
            `,
        },

        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 17, 'id': 17, 'status': true, 'tipoFaq': 1,
            'Titulo': "Posso ser desclassificado da promoção?",
            'Texto': `<p style='text-align: justify;'>Os Participantes poderão ser excluídos automaticamente da Campanha em caso de tentativa de fraude, falsidade ou qualquer ato de má fé comprovado, não preenchimento dos requisitos previamente determinados e/ou em decorrência de informações incompletas, incorretas ou equivocadas, de acordo com as regras previstas no Regulamento, disponível na página inicial da campanha. </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 18, 'id': 18, 'status': true, 'tipoFaq': 1,
            'Titulo': "Tenho algum custo para poder usufruir da minha premiação?",
            'Texto': `<p style='text-align: justify;'>Não. Os carros serão entregues prontos para uso! Com todos os custos pagos pela Chevrolet, como emplacamento, licenciamento, documentação, IPVA e deslocamento até a cidade do ganhador.  </p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 19, 'id': 19, 'status': true, 'tipoFaq': 1,
            'Titulo': "Ganhando o sorteio, em quanto tempo recebo meu prêmio?",
            'Texto': `<p style='text-align: justify;'>O prêmio será entregue, livre e desembaraçado de qualquer ônus, na Concessionária mais próxima da residência do contemplado, no prazo máximo de 30 (trinta) dias, a contar da data da apuração, de acordo com o artigo 5º do Decreto nº 70.951/72, desde que atendidos todos os requisitos apresentados no Regulamento, disponível na página inicial da campanha.</p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 20, 'id': 20, 'status': true, 'tipoFaq': 1,
            'Titulo': "O cadastro foi feito errado para a promoção, como posso ajustar?",
            'Texto': `<p style='text-align: justify;'>Caso o cadastro não tenha sido feito em nome do proprietário, basta mandar um e-mail para o SAC da Chevrolet recallchevrolet@gm.com, contendo o documento do proprietário e o documento do veículo para o ajuste no cadastro. Assim é cancelada a antiga participação (no prazo de até 03 dias), e o proprietário deverá se cadastrar, gerando nova participação na campanha e número da sorte. Esse processo só poderá ocorrer em até 03 dias antes da data final da campanha.</p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 21, 'id': 21, 'status': true, 'tipoFaq': 1,
            'Titulo': "Em caso de dúvidas, com quem posso entrar em contato?",
            'Texto': `<p style='text-align: justify;'>Para qualquer tipo de dúvidas, ou auxílio, entre em contato com o SAC da Chevrolet através do nosso e-mail recallchevrolet@gm.com.</p>
            `,
        },
        {
            'DataCadastro':  "2021-04-02T12:00:29.0551555",
            'Ordem': 22, 'id': 22, 'status': true, 'tipoFaq': 1,
            'Titulo': "Esta campanha está em conformidade com as normas da SECAP?",
            'Texto': `<p style='text-align: justify;'>“Seu Chevrolet Seguro – Sorteio” foi devidamente aprovada, sob o certificado de autorização da SECAP/ME nº 04.012270/2021.</p>
            `,
        }
    ]);
    const [load, setLoad] = React.useState(false);

    // useEffect(() => {
    //     trackPromise(
    //         ApiServices.apiGet('faqs')
    //         .then(res => {
    //             if (res.success) {
    //                 setFaqlist(res.data)
    //             } else {
    //                 props.showMsgW(`Erro ao carregar Faqs`)
    //             }
    //             setLoad(true)
    //         }).catch(res => {
    //             setLoad(true)
    //             props.showMsgW(`Erro ao carregar Faqs`)
    //         })
    //     )
    // },[]);

    function handleVerify(valor){
        console.log(valor);
        props.handleVerify(valor);
    }


    return (
        <>
            <GoogleReCaptcha onVerify={handleVerify} />
            <div className="FaqTitle">
                <div className="container">
                    <div className="row">
                        <div className="col-12 FaqTitleContent">
                            <button onClick={() => props.history.goBack()}>
                                <img src={iconArrow} alt="voltar" /> Voltar
                            </button>
                            <div>
                                <h2>
                                    Campanha: SEU CHEVROLET SEGURO - SORTEIO DE 3 (TRÊS) ONIX 0KM 
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container contentDefault" >
                <div className='row'>
                    <div className='col-12'>
                        <h1>Tire suas Dúvidas</h1>
                    </div>
                </div>
                <Faqs faqs={faqlist} load={load} />
            </div>
        </>
    )


}


const mapStateToProps = store => ({
    snackbarState: store.snackbarState
});
const mapDispatchToProps = dispatch => bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FaqSorteio);