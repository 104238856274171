import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import "./styles.cadastro.scss";
import { useEffect } from "react";
import ApiServices from "../../services/apiServices";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Utils from "../../services/serviceUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import imgNf from "../../assets/img/numeroNF.png";
import ValorTotal from "../../assets/img/valor01.png";
import imgEmissao from "../../assets/img/dataEmissao.png";
import imgCnpj from "../../assets/img/Cnpj.png";
import imgRazao from "../../assets/img/razaoSocial.png";
import imgInfo from "../../assets/img/info.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import iconeArquivo from "../../assets/img/icons/iconeArquivo.png";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import Mask from "../../services/mask";

function EditarNotaFiscal(props) {
  const [resNF, setResNF] = useState([]);
  const [verPdf, setVerPdf] = useState(false);
  const valorCampoNF = useRef(null);
  const history = useHistory();
  const [OpenNumeroNf, setOpenNumeroNf] = React.useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const refPdf = useRef(null);
  const modalOpenNumeroNf = () => {
    setOpenNumeroNf(true);
  };
  const modalCloseNumeroNf = () => {
    setOpenNumeroNf(false);
  };

  const [OpenDataEmissao, setOpenDataEmissao] = React.useState(false);
  const modalOpenDataEmissao = () => {
    setOpenDataEmissao(true);
  };
  const modalCloseDataEmissao = () => {
    setOpenDataEmissao(false);
  };

  const [OpenCnpj, setOpenCnpj] = React.useState(false);
  const modalOpenCpnj = () => {
    setOpenCnpj(true);
  };
  const modalCloseCnpj = () => {
    setOpenCnpj(false);
  };

  const [OpenRazao, setOpenRazao] = React.useState(false);
  const modalOpenRazao = () => {
    setOpenRazao(true);
  };
  const modalCloseRazao = () => {
    setOpenRazao(false);
  };

  const [open, setOpen] = React.useState(false);

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    ApiServices.apiGetWithIdAndToken(
      "notasfiscais",
      props.match.params.id
    ).then((res) => {
       
      
      setResNF(res.data);  
      valorCampoNF.current.value = res.data.valorTotalNF.toLocaleString("pt-br",{ style: "currency", currency: "BRL" });
   
   
    });
  }, [ ]);  

 
  function fnVerPdf() {
    if (verPdf == false) {
      setVerPdf(true);
    } else {
      setVerPdf(false);
    }
  }

  return (
    <>
      <div className="container contentDefault">
        <div className="col-12"> 
          <br />
          <div className="titulo">
            <span>
              <strong>Notas Fiscal </strong>
            </span>
          </div>
          <br />

          <p className="p-1">
            <span className="span-1">Dados da Nota Fiscal</span> <br />
          </p>
          <Dialog
            open={OpenNumeroNf}
            maxWidth={"lg"}
            onClose={modalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <IconButton aria-label="close" onClick={modalCloseNumeroNf}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <img src={imgNf} />
            </DialogContent>
          </Dialog>

          <div className="row">
            <div className=" col-sm-12 col-md-4 form-outline mb-3">
              <label className="form-label m0">Número da NF*</label>
              <strong style={{ cursor: "pointer"}} onClick={modalOpenNumeroNf}>
                {props.iconBtn ? (
                  props.iconBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>

              <input
                className="form-control"
                type="text"
                id="numeroNF"
                placeholder="Insira o número da Nota Fiscal"
                value={resNF.numeroNF}
                disabled
              />
            </div>
            <Dialog
              open={OpenDataEmissao}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseDataEmissao}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={imgEmissao} />
              </DialogContent>
            </Dialog>

            <div className=" col-sm-12 col-md-3 form-outline mb-3">
              <label className="form-label m0">Data de emissão*</label>
              <strong
                style={{ cursor: "pointer" }}
                onClick={modalOpenDataEmissao}
              >
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="data"
                placeholder="dd/mm/aaaa"
                value={resNF.dataEmissaoNF}
                disabled
              />
            </div>
            <Dialog
              open={OpenCnpj}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseCnpj}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={imgCnpj} />
              </DialogContent>
            </Dialog>

            <div className="col-sm-12 col-md-5 form-outline mb-3">
              <label className="form-label m0">CNPJ do prestador*</label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpenCpnj}>
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="cnpj"
                placeholder="00.000.000/0000-00"
                value={Mask.cnpj(resNF.cnpjPrestador)}
                disabled
              />
            </div>
            <Dialog
              open={OpenRazao}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseRazao}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={imgRazao} />
              </DialogContent>
            </Dialog>

            <div className="col-sm-12 col-md-8 form-outline mb-3">
              <label className="form-label m0">
                Razão social do prestador*
              </label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpenRazao}>
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                className="form-control"
                type="text"
                id="razaoSocial"
                placeholder=""
                value={resNF.razaoSocialPrestador}
                disabled
              />
            </div>
            <Dialog
              open={open}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img src={ValorTotal} />
              </DialogContent>
            </Dialog>

            <div className="col-sm-12 col-md-4 form-outline mb-3">
              <label className="form-label m0">Valor total da NF*</label>
              <strong style={{ cursor: "pointer" }} onClick={modalOpen}>
                {props.textoBtn ? (
                  props.textoBtn
                ) : (
                  <img src={imgInfo} width="15" className="mx-2" alt="" />
                )}
              </strong>
              <input
                ref={valorCampoNF}
                className="form-control"
                type="text"
                id="valorTotal"
                placeholder="R$ 0.000,00"
                disabled
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <p>
              <span className="span-4">Nota Fiscal</span>
              <br />
            </p>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            {!pdfFile && (
              <div
                className="imgIconeAnexar "
                onClick={() => {
                  fnVerPdf();
                }}
              >
                <img className="mx-3" src={iconeArquivo} alt="icone anexar" />


                {verPdf ? "OCULTAR ARQUIVO" : "VISUALIZAR ARQUIVO"}
                <br />
                <br />
                {verPdf && 
                <embed src={resNF.urlDocumentoNF} type="application/pdf" width={"100%"} height={'600'} />}
              </div>
            )}
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 text-center">
            <button
              className="btn btn-secondary col-md-3 col-sm-12 m-3 p-3"
              onClick={() => history.push(`/notas-fiscais`)}
            >
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditarNotaFiscal);
