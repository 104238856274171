import React, { useEffect } from "react";
//import * as moment from 'moment'
import "./home.scss";

//import Mask from '../../services/mask';
//import Utils from '../../services/serviceUtils';

import { trackPromise } from "react-promise-tracker";
import ApiServices from "../../services/apiServices";

import StatusRecall from "../../components/StatusRecall/StatusRecall";

/////////////////////////////
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";

import Regulamento from "../../components/Docs/RegulamentoBrinde";
import { Link } from "react-router-dom";
import LinkUi from '@material-ui/core/Link';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import sorteioImg from "../../assets/img/banners/sorteio-002a.jpg";
// import CircularProgress from '@material-ui/core/CircularProgress';

import valeImg from "../../assets/img/banners/banner-combustivel-mobile.jpg";
//import BannerGMRecall from '../../assets/img/banners/BannerGMRecall.png';
import bannerA from "../../assets/img/banners/banner-home01.jpg";
import bannerAm from "../../assets/img/banners/banner-home01-m.jpg";
import fileRegulamento from "../../assets/img/docs/Regulamento-Voucher.pdf";
import Modal from "../../components/Modal/Modal";
import { useDebugValue } from "react";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Recall = (props) => {
  //const dateNow = new Date().toISOString()

  const [openModal, setOpenModal, setModalContent, modalContent, purify] =
    React.useState(false);

  return (
    <>
      <DialogContent>
        {props.type === "pendente" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <StatusRecall status={false} />
              <p className="big bold">Data de atualização: {props.date}</p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>Agende agora seu Recall de Airbags Takata</h3>

              <p className="big">
                Fazendo o Recall, <strong>você ganha R$ 500,00 </strong>
                de vale-combustível no app Shell Box além de concorrer até 3
                (três) Onix 0km.
              </p>
            </div>
            <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
              <a
                href="https://www.chevrolet.com.br/servicos/recalls/airbag-takata/solicitar-troca"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-block btn-lg"
              >
                Clique e agende
              </a>
            </div>
          </div>
        )}

        {props.type === "naoEnviado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                não envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <p className="big bold">
                Data de atualização: {props.date}
                {/* {moment(dateNow).format('DD/MM/YYYY')} */}
              </p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>
                Saiba se seu veículo está participando de algum outro Recall da
                Chevrolet.
              </h3>
            </div>
            <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
              <a
                href="https://www.chevrolet.com.br/servicos/recalls"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-block btn-lg"
              >
                Consulte
              </a>
            </div>
          </div>
        )}

        {props.type === "executado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <StatusRecall status={true} />
              <p className="big bold text-center">
                Serviço realizado em: {props.servico}
              </p>
              <p className="big bold">Data de atualização: {props.date}</p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>Aproveite e concorra até 3 (três) Onix 0km.</h3>
            </div>
            <div className="col-12 mt30 text-center center">
              <button className="btn btn-link">
                <Regulamento
                  textoBtn={"Conheça o regulamento do Sorteio"}
                  styleBtn={"container"}
                  origem={"sorteio"}
                />
              </button>
            </div>
            {/* <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
                        <button className="btn btn-primary btn-block btn-lg">
                            Conheça o regulamento do sorteio
                        </button>
                    </div> */}
          </div>
        )}

        {props.type !== "pendente" &&
          props.type !== "naoEnviado" &&
          props.type !== "executado" && (
            <div className="row">
              <div className="col-md-12 col-sm-12">Status não encontrado</div>
            </div>
          )}
      </DialogContent>
    </>
  );
};

function Home(props) {
  const [chassi, setChassi] = React.useState("");
  const [chassiResponse, setChassiResponse] = React.useState("");
  const [chassiData, setChassiData] = React.useState("");
  const [dataServico, setdataServico] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const modalOpenRecall = () => {
    setOpen(true);
  };
  const modalCloseRecall = () => {
    setOpen(false);
  };

  const [openNew, setOpenNew] = React.useState(false);
  const modalOpenNew = () => {
    setOpenNew(true);
  };
  const modalCloseNew = () => {
    setOpenNew(false);
  };

  const changeChassi = (event) => {
    if (event.target.value.length <= 17) {
      setChassi(event.target.value);
    }
  };

  const sendChassi = () => {
    if (chassi.length === 17) {
    

      trackPromise(
        //ApiServices.apiPost('valeCombustivel/buscarChassi', {chassi})
        ApiServices.apiGet(`valeCombustivel/statusChassi?chassi=${chassi}`)
          .then((res) => {
            if (res.success) {
              if (res.data.statusCodido === "1") {
                setChassiResponse("naoEnviado");
              } else if (res.data.statusCodido === "2") {
                setChassiResponse("executado");
              } else if (res.data.statusCodido === "3") {
                setChassiResponse("pendente");
              }

              setChassiData(res.data.dataAtualizacao);
              setdataServico(res.data.dataServico);

              modalOpenRecall();
            } else {
              props.showMsgW(res.errors);
            }
          })
          .catch((res) => {
            props.showMsgW(`Erro ao buscar chassi`);
          })
      );
    } else {
      props.showMsgW(`Chassi inválido`);
    }
  };

  const preventDefault = (event) =>{
    window.location.href = 'https://www.chevrolet.com.br/servicos/recalls/airbag-takata/solicitar-troca';
  };

  useEffect(() => {
    modalOpenNew();
  }, []);

  function handleVerify(valor){
    //console.log(valor);
    props.handleVerify(valor);
  }

  return (
    <>
      <GoogleReCaptcha onVerify={handleVerify} />
      <div className="bgMainFeature">
        {/* <Link to="vale-combustivel"><img src={bannerA} alt="banner" className="d-none d-md-block d-lg-block responsive" /></Link> */}
        <a href={fileRegulamento} target="_blank" rel="noopener noreferrer">
          <img
            src={bannerA}
            alt="banner"
            className="d-none d-md-block d-lg-block responsive"
          />
        </a>
        <a href={fileRegulamento} target="_blank" rel="noopener noreferrer">
          <img
            src={bannerAm}
            alt="banner"
            className="d-block d-md-none d-lg-none responsive"
          />
        </a>
      </div>

      <div className="container contentDefault">
        <div className="row">
          <div className="col-12">
            <h2>
              Quer saber se seu veículo está envolvido no Recall de Airbags
              Takata?
            </h2>
          </div>
          <div className="col-md-8">
            <div>
              <h2>Digite o número do chassi:</h2>
              <p>
                Para encontrar o número do seu chassi, verifique a documentação
                do seu veículo ou procure no canto inferior do vidro dianteiro
                do passageiro. O chassi é composto por 17 caracteres, entre
                números e letras.
              </p>
              <br />
            </div>
          </div>
          <div className="col-md-7">
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Digite o número do chassi"
                value={chassi}
                onChange={changeChassi}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-info btn-lg"
                  type="button"
                  onClick={sendChassi}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bgFeature">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>Conheça as vantagens vigentes para seu veículo</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 center">
              <Link to="vale-combustivel">
                <img src={valeImg} alt="banner" className="responsive mAuto" />
              </Link>
            </div>
            <div className="col-md-6 center">
              <Link to="sorteio">
                <img src={sorteioImg} className="responsive mAuto" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        maxWidth={"sm"}
        onClose={modalCloseRecall}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton aria-label="close" onClick={modalCloseRecall}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Recall
          type={chassiResponse}
          chassi={chassi}
          date={chassiData}
          servico={dataServico}
        />
      </Dialog>

      <Dialog
        open={openNew}
        maxWidth={"sm"}
        onClose={modalCloseNew}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton aria-label="close" onClick={modalCloseNew}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div>
          <DialogTitle id="alert-dialog-title">ATENÇÃO!</DialogTitle>
          <DialogContent>
          A CHEVROLET E SUA REDE DE CONCESSIONÁRIAS NÃO SOLICITAM O ENVIO DE DOCUMENTOS PARA ESTA PROMOÇÃO ATRAVÉS DE WHATSAPP.
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
