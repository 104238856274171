import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Storage from "../../services/storage";

function Logout() {
  useEffect(() => {
    Storage.del("DEVP_USER_INFOS");
    //REDIRECIIONAR PARA HOME
  }, []);

  return (
    <>
      <Redirect to="/" />
    </>
  );
}

export default Logout;
