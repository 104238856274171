import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import bannerEsqueciSenha from "../../assets/black/bannerEsqueciSenha.png";
import bannerCampannhaPremiada from "../../assets/img/banners/servico-premiado/logo.png";
import ApiServices from "../../services/apiServices";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import { useRef } from "react";

function EsqueciSenha(props) {
  const history = useHistory();
  const [esqueceuSenhaEmail, setEsqueceuSenhaEmail] = useState("");
  const inputEmail = useRef(null);

  const [alertaCampo, setAlertaCampo] = useState({
    email: {
      status: "d-none",
      alerta: "E-mail invalido",
    },
  });

  function fnBtEnviar() {
    if (esqueceuSenhaEmail.includes("@") == false) {
      setAlertaCampo((prev) => ({
        ...prev,
        email: { status: "", alerta: "E-mail invalido" },
      }));
    }

    const init = {
      userName: esqueceuSenhaEmail,
    };

    if (esqueceuSenhaEmail.includes("@") == true) {
      ApiServices.apiPost("participantes/cliente/resetar/senha", init)
        .then((res) => {
         

          if (res.success == true) {
            msg("s", "E-mail enviado com sucesso para sua caixa de entrada!");
            history.push("/");
          } else {
          }
        })
        .catch((res) => {
          msg(
            "w",
            "E-mail não localizado em nossa base de cadastros. Favor inserir um e-mail válido"
          );
          inputEmail.current.focus();
          setEsqueceuSenhaEmail("");
        });
    }
  }

  function msg(tipo, text) {
    //S W E
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }

  return (
    <>
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-6">
            <img className="responsive" src={bannerEsqueciSenha}></img>
          </div>

          <div className="col-md-6">
            {/* <img className="col-4" src={bannerCampannhaPremiada}></img> */}

            <div className="col-12">
              <div
                className="d-flex align-items-end btn"
                onClick={() => window.history.back()}
              >
                <span className="glyphicon glyphicon-menu-left"></span>
                <p>Voltar</p>
              </div>

              <h4 className="col-12 pad0 mt20">Esqueceu a senha?</h4>
              <br />
              <p className="col-12 pad0 mt20">
                Vamos enviar recuperação de senha para seu e-mail cadastrado,
                tudo bem?
              </p>
              <br />

              <label className="mb-0">E-mail</label>
              <input
                type="text"
                className="form-control"
                maxLength="200"
                placeholder="Informe seu e-mail"
                value={esqueceuSenhaEmail}
                autoFocus
                ref={inputEmail}
                onChange={(e) => {
                  alertaCampo.email.status = "d-none";
                  e.persist();
                  setEsqueceuSenhaEmail(e.target.value);
                }}
              />

              <p className={alertaCampo.email.status}>
                <em>{alertaCampo.email.alerta}</em>
              </p>

              <button
                className="col-12 btn btn-primary mt-5 pad10 "
                onClick={fnBtEnviar}
              >
                {" "}
                ENVIAR{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EsqueciSenha);
