import React from 'react';
import './statusRecall.scss';

import carRed from '../../assets/img/icons/recall-icon-red.png';
import carGreen from '../../assets/img/icons/recall-icon-green.png';

const StatusRecall = (props) => {
    return (
        <div>
            { 
                props.status === false &&
                <p className="big"><strong>Status:</strong></p>
            }
            
            <span className={`statusRecall ${props.status ? 'green' : 'red'}`}>
                { 
                    props.status === true ?
                    <img src={carGreen} alt="icon car green" /> :
                    <img src={carRed} alt="icon car red" />
                }
                {
                    props.status === true ?
                    <p>Recall executado</p> :
                    <p>Recall não executado</p>
                }
            </span>
        </div>
    )
}

export default StatusRecall;