import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 10000,
};

export default function Realistic() {
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(500 * particleRatio)
      });
  }, []);

   useEffect(() => {
    makeShot(0.25, {
      colors:['#3FF0ED', '#000', '#555','#999', '#bbb','#eee','#148380', '#3FF0ED'],
      spread: 26,
      startVelocity: 55
    });
    
    makeShot(0.2, {
      colors:['#3FF0ED', '#000', '#555','#999', '#bbb','#eee','#148380', '#3FF0ED'],
      spread: 60 
    });
    
    makeShot(0.35, {
      colors:['#3FF0ED', '#000', '#555','#999', '#bbb','#eee','#148380', '#3FF0ED'],
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      colors:['#3FF0ED', '#000', '#555','#999', '#bbb','#eee','#148380', '#3FF0ED'],
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2   
    });
    
    makeShot(0.1, {
      colors:['#3FF0ED', '#000', '#555','#999', '#bbb','#eee','#148380', '#3FF0ED'],
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);
  
  return (
    <>
    
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </>
  );
}
