import {
    UPDATEUSER,
} from '../actions/actionTypes';

const initialState = {
    userInfos: JSON.parse(sessionStorage.getItem(`DEVP_USER_INFOS`))
};

const shareInfosReducer = (state = initialState, action) => {
    // debugger
    switch (action.type) {
        case UPDATEUSER:
            sessionStorage.setItem('DEVP_USER_INFOS', JSON.stringify(action.payload))
            return {
                ...state,
                userInfos: JSON.parse(sessionStorage.getItem(`DEVP_USER_INFOS`))
            };
        default:
            return state;
    }
};

export default shareInfosReducer;