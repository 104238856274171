import React, { Component, useEffect, useState } from "react";

//import DocPolitica from '../../components/Docs/Texto/PoliticaConteudo';
import RegulamentoConteundo from "../../components/Docs/Texto/RegulamentoConteudo";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import RegulamentoConteundoSorteio from "../../components/Docs/Texto/RegulamentoConteudoSorteio";
import { Link, Redirect } from "react-router-dom";

function Politica(props) {
  function handleVerify(valor) {
    // console.log(valor);
    // this.props.handleVerify(valor);
  }

  const pathname = () => {
    return window.location.pathname;
  };

  useEffect(() => {
    


  }, []);

  return (

    <div className="container contentDefault">
      <h1 className="col-12 text-center regulamentoPage">Regulamento</h1>

      <div className="menuItens ">
        <ul className=" col-12 p-4 d-flex justify-content-center">

          <li className={`${pathname() === "/regulamento/vale-combustivel" ? "active" : ""}`}>
            <Link to="/regulamento/vale-combustivel">  <span className="h4">Vale-Combustível</span></Link>
          </li>

          {/* <li className={`${pathname() === "/regulamento/sorteio" ? "active" : ""}`}>
            <Link to="/regulamento/sorteio"><span className="h4">Sorteio</span></Link>
          </li> */}
        </ul>
      </div>


      <GoogleReCaptcha onVerify={handleVerify} />
      <div className="row">
        <div className="col-12">
          {/* <DocPolitica></DocPolitica> */}

          {pathname() === "/regulamento" && (
            <Redirect to="/regulamento/vale-combustivel" />
          )}
          {pathname() === "/regulamento/vale-combustivel" && (
            <RegulamentoConteundo />
          )}
          {pathname() === "/regulamento/sorteio" && (
            <RegulamentoConteundoSorteio />
          )}
        </div>
      </div>
    </div>
  );
}

export default Politica;
