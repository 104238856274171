import React  from 'react';
import './callaction.scss';

const CallAction = (props) => {
    
    return (
        <div className="SectionCallAction">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallAction;