import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Storage from '../services/storage'
import Private from './Private'

const PrivateRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route  {...rest} render={props => (
            Storage.getUser() 
            ? <Private><Component {...props} /></Private>
            : <Redirect to={{ pathname: '/logout', state: { from: props.location } }} />
        )} />
    )
}

export default PrivateRoutes