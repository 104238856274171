// react dependencia
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React,  {useEffect} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";

// store
import { Store } from "./store";

// plugins
import { SnackbarProvider } from "notistack";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

// css
import "./styles/style.scss";

// components
import AlertSnackbar from "./components/AlertMsg/AlertMsg.js";
import LoadingBar from "./components/LoadingBar/LoadingBar.js";

// views
import DefaultRoutes from "./layouts/Defaultroutes";

import Notfound from "./views/NotFound/NotFound";
import Home from "./views/Home/Home";
import ValeCombustivel from "./views/ValeCombustivel/ValeCombustivel";
import Sorteio from "./views/Sorteio/Sorteio";
import Faq from "./views/Faq/Faq";
import FaqSorteio from "./views/Faq/FaqSorteio";
import Politica from "./views/Politica/Politica";
import RegulamentoPage from "./views/Regulamento/RegulamentoPage";

import * as serviceWorker from "./serviceWorker";
//import SorteioCaptcha from './views/Sorteio/SorteioCaptcha';

import GeneralCaptcha from "./components/GeneralCaptcha/GeneralCaptcha";
import Login from "./views/Login/Login";
import Cadastro from "./views/Cadastro/Cadastro";
import EsqueciSenha from "./views/EsqueciSenha/EsqueciSenha";
import RecuperarSenha from "./views/RecuperarSenha/RecuperarSenha";
import PrivateRoutes from "./layouts/Privateroutes";
import { default as EditarNotaFiscal } from './views/NotasFiscais/editar';
import TesteRoleta from "./views/TesteRoleta/Roleta";
import Auth from "./views/Auth/index.js";

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Provider store={Store}>
    <SnackbarProvider
      maxSnack={1}
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          <Close />
        </Button>
      )}
    >
      <AlertSnackbar />
    </SnackbarProvider>
    <LoadingBar />
    <BrowserRouter>
      <Switch>
        {/* <DefaultRoutes path="/" exact component={Auth} />  */}
        <DefaultRoutes path="/" exact component={TesteRoleta} />

        <DefaultRoutes path="/como-funciona" exact component={GeneralCaptcha} />

        <DefaultRoutes path="/regulamento" exact component={GeneralCaptcha} />
        <DefaultRoutes path="/regulamento/vale-combustivel" exact component={GeneralCaptcha}/>
        <DefaultRoutes path="/regulamento/sorteio" exact component={GeneralCaptcha}/>
        
        <DefaultRoutes path="/faq" exact component={GeneralCaptcha} />
        <DefaultRoutes path="/faq/vale-combustivel" exact component={GeneralCaptcha}/>
        <DefaultRoutes path="/faq/sorteio" exact component={GeneralCaptcha}/>


        <DefaultRoutes path="/notas-fiscais" exact component={GeneralCaptcha}/>
        <DefaultRoutes path="/cadastro-notas-fiscais" exact component={GeneralCaptcha} />
        <PrivateRoutes path='/notas-fiscais/detalhes/:id' exact component={EditarNotaFiscal} />

        <DefaultRoutes path="/black-friday-premiada" exact component={GeneralCaptcha} />
        <DefaultRoutes path="/roleta" exact component={GeneralCaptcha} />

        <DefaultRoutes path="/login" exact component={GeneralCaptcha} />
        <DefaultRoutes path="/cadastro" exact component={Cadastro} />
        <DefaultRoutes path="/esqueci-senha" exact component={GeneralCaptcha} />

        <DefaultRoutes path="/logout" exact component={GeneralCaptcha} />


        <DefaultRoutes path="/politica-de-privacidade" exact component={GeneralCaptcha}/>
        <DefaultRoutes path="/sorteio" exact component={GeneralCaptcha} />
        <DefaultRoutes path="/politica-de-privacidade" exact component={GeneralCaptcha}/>



        <DefaultRoutes path="/recuperarSenha" exact component={RecuperarSenha}/>
 
        <DefaultRoutes component={Notfound} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

