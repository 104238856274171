import React from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const Private = props => {
    return (
        <div id="wrapper">
            <div id="top">
                <Header></Header>
            </div>

            <div>
                {props.children}
            </div>
           
            <div id="footer">
                <Footer></Footer>
            </div>
        </div>
    )
}

export default Private