import React from "react";
import Background from "../../assets/black/Bg.png";
import Roleta from "../../assets/black/roleta.png";
import Seta from "../../assets/black/seta.png";
import Botao from "../../assets/black/botao.png";
// import "./style.scss";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import Ganhou from "../../assets/black/vale.png";
import Perdeu from "../../assets/black/naofoi.png";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Realistic from "./Realistic";
import Fireworks from "./Fireworks";
import ApiServices from "../../services/apiServices";
import Storage from "../../services/storage";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { wait } from "@testing-library/react";
export default function RRoleta() {
  const [resultado, setResultado] = useState("");
  const [open, setOpen] = useState(false);
  const [campanhaAtiva, setCampanhaAtiva] = useState(true);
  const [tentarNovamente, setTentarNovamente] = useState([]);
  const [openModalNovamente, setOpenModalNovamente] = useState(false);
  const [balao, setBalao] = useState(0);
  const [jogar, setJogar] = useState(99);

  const history = useHistory();
  const modalOpen = () => {
    setOpen(true);
  };
  const modalClose = () => {
    setOpen(false);
    setResultado("");
  };

  async function fnTentarNovamente() {
    await ApiServices.apiRoletaPostWithToken(
      "participante/cupom/resgatar"
    ).then((res) => {
      setTentarNovamente(res); // setOpenModalNovamente(true);
      fnGrausRolera(res); 
      fnConfirmar(); 

      setTimeout(() => {
        if (res.ganhador == false) {
          setResultado("perdeu");
          modalOpen();
        }
        if (res.ganhador == true) {
          setResultado("ganhou");
          modalOpen();
        }
        setJogar(1);
      }, 6000);
    });
  }

  async function fnConfirmar() {
    ApiServices.apiRoletaPostWithToken("participante/cupom/confirmar");
  }

  function fnGrausRolera(res) {
    var g = 0;
    if (res.ganhador) {
      g = 45;
    } else {
      g = 0;
    }

    var roleta = document.querySelector(".roleta");
    roleta.classList.add("girar");
    roleta.style.transform = `rotate(${g + 3600}deg)`;

    // var bts = document.querySelectorAll(".btn");
    // for (let bt of bts) {
    //   bt.disabled = true;
    // }
  }

  useEffect(() => {
    ApiServices.apiRoletaGet("participante/campanhaAtiva").then((res) =>
      setCampanhaAtiva(res)
    );
  }, []);

  function fnResumo() {
    ApiServices.apiRoletaGetWithToken("participante/resumo").then((res) => {
      if (res.balao < 1) {
        setJogar(0);
      } 
      setBalao(res.balao);
    });
  }
  useEffect(() => {
    fnResumo();
  }, []);

  return (
    <div className="d-flex align-items-center flex-column">
      <Dialog
        open={!campanhaAtiva}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <p className="m-5 p-5">ESTA CAMPANHA NÃO ESTA MAIS ATIVA !</p>
        <button
          className="btn btn-primary"
          onClick={() => history.push("/black-friday-premiada")}>
          VOLTAR
        </button>
      </Dialog>

      <Dialog
        open={open}
        maxWidth={"sm"}
        onClose={modalClose}
        // disableBackdropClick={true}
        // disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <CloseIcon className="botaoFechar" onClick={modalClose} />

        {resultado == "perdeu" && <img src={Perdeu} alt="" />}
        {resultado == "ganhou" && <img src={Ganhou} alt="" />}
      </Dialog>

      {resultado == "ganhou" && <Realistic />}
      {resultado == "ganhou" && <Fireworks />}

      {jogar == 0 ? (
        <h3 className="legendaRoleta my-5">
          Você não possui mais jogadas disponíveis!
        </h3>
      ) : (
        <h3 className="legendaRoleta my-5">
          Você ainda tem {balao < 1 ? '0': balao} chance(s) para ganhar
        </h3>
      )}

      <div className="tudo">
        <img src={Background} alt="" className="back" />
        <div className="divRoleta">
          <img src={Roleta} alt="" className="roleta" />
        </div>
        <img src={Seta} alt="" className="seta" />

        {jogar == 99 && (
          <button
            className="btn btn-primary px-5 py-2 botaoJogar"
            onClick={() => {
              fnTentarNovamente();
              setBalao(balao - 1)
              setJogar(2);
              
            }}>
            JOGAR
          </button>
        )}
        {jogar == 1 && (
          <button
            className="btn btn-primary px-5 py-2 botaoJogar"
            onClick={() => {
              window.location.reload(false);
            }}>
            Tentar Novamente
          </button>
        )}
      </div>
    </div>
  );
}
