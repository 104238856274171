import React, { Fragment } from 'react';
import './faqs.scss';

import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import qrCode from '../../assets/img/qrcode.jpg'

const Faqs = (props) => {
    //Ordem, id, Texto, Titulo
    const [faqOpen, setFaqOpen] = React.useState(null);

    const toogleFaq = (id) => {
        if (faqOpen === id) {
            setFaqOpen(null)
        } else {
            setFaqOpen(id)
        }
    }

    return (
        <Fragment>
            {
                props.faqs.length > 0 ? 
                <div className="row faq">
                    {
                        props.faqs.sort((a, b) => a.Ordem > b.Ordem ? 1 : -1).map((faq) => {
                            return (
                                <div key={faq.id} className={faqOpen === faq.id ? 'col-12 active' : 'col-12'} >
                                    <div className="pergunta" onClick={() => toogleFaq(faq.id)}>
                                        {faq.Titulo}
                                        {
                                            faqOpen === faq.id ? <Remove className="arrow" /> : <Add className="arrow" />
                                        }
                                   
                                    </div>
                                 

                                    <div className='resposta' dangerouslySetInnerHTML={{ __html: faq.Texto }} />
                                   
                                     <div className='resposta p-0'>
                                           {faq.id === 18 ? <img className='m0 p0 col-sm-12 col-md-4' width='20%' src={qrCode} alt='qrcode'/> : null}
                                        </div>
                                </div>
                            )
                        })
                    }
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        {
                            props.load ? 
                            'Nenhum Faq encontrado'
                            :
                            'Carregando...'
                        }
                    </div>
                </div>
            }
            
        </Fragment>
    )
}

export default Faqs;