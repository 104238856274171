
import React, { Fragment, useEffect , useCallback } from 'react';
import { Link } from 'react-router-dom';

import Mask from '../../services/mask';
//import Utils from '../../services/serviceUtils';

/////////////////////////////
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMsgS, showMsgW, showMsgE } from '../../store/actions/snackbarActions.js';


import { trackPromise } from 'react-promise-tracker';
import ApiServices from '../../services/apiServices';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';

import HelpOutline from '@material-ui/icons/HelpOutline';

import Regulamento from '../../components/Docs/RegulamentoBrinde'
import Banner from '../../components/Banner/Banner';
import CallAction from '../../components/CallAction/CallAction';

import fileRegulamento from '../../assets/img/docs/Regulamento-Sorteio.pdf';

import bannerImgSorteio from '../../assets/img/banners/sorteio-002.jpg';
import bannerImgSorteioMobile from '../../assets/img/banners/sorteio-002b.jpg';

import bannerImgSorteioV2 from '../../assets/img/banners/sorteio-004.png';
import bannerImgSorteioMobileV2 from '../../assets/img/banners/sorteio-004b.png';

import rouletteImg from '../../assets/img/Gif-Roulette.gif';
import bannerBg from '../../assets/img/banners/bg0001.png';
import bannerImg from '../../assets/img/banners/car-2.png';
import bannerTitle from '../../assets/img/banners/Sorteio-titulo.png';
import { load } from 'react-cookies';
import LinkUi from '@material-ui/core/Link';

//import ReCAPTCHA from "react-google-recaptcha";

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha,
    withGoogleReCaptcha
  } from 'react-google-recaptcha-v3';

import ScriptTag from 'react-script-tag';

import {Helmet} from "react-helmet";


const FormCadastro = (props) => {
    const [cadastro, setCadastro] = React.useState({
        "nome": '', 
        "email": '', 
        "cpf": '', 
        "telefone": '',
        "chassi": '',
        "permission": false,
        "regulamento": false,
        "veiculoId": ''
    });

    // const [cadastro, setCadastro] = React.useState({
    //     "nome": 'teste', 
    //     "email": 'asdf@gmail.com', 
    //     "cpf": '23159390896', 
    //     "telefone": '2222222222',
    //     "chassi": '',
    //     "permission": true,
    //     "regulamento": true,
    //     "veiculoId": ''
    // });
    const [numeroSorteio, setNumeroSorteio] = React.useState('');

    const [load, setLoad] = React.useState(false)
    const [statusRet, setStatusRet] = React.useState({
        "show": 'inicial', 
        "msg": ''
    })

    const handleChangeForm = (campo = '') => event => {
        event.persist();
        //debugger
        switch (campo) {
            case 'nome':
                setCadastro(prevState => ({...prevState,
                    [campo]: event.target.value
                }))
                break;
            case 'email':
                setCadastro(prevState => ({...prevState,
                    [campo]: event.target.value
                }))
                break;
            case 'cpf':
                setCadastro(prevState => ({...prevState,
                    [campo]: Mask.cpf(event.target.value)
                }))
                break;
            case 'telefone':
                setCadastro(prevState => ({...prevState,
                    [campo]: Mask.cel(event.target.value)
                }))
                break;
            case 'chassi':
                if (event.target.value.length <= 17) {
                    setCadastro(prevState => ({...prevState,
                        [campo]: event.target.value
                    }))
                }
                break;
            case 'permission':
                setCadastro(prevState => ({...prevState,
                    [campo]: event.target.checked
                }))
                break;
            case 'regulamento':
                setCadastro(prevState => ({...prevState,
                    [campo]: event.target.checked
                }))
                break;
            default:
                
        }
    };

    const validaCadastro = () => {

        setLoad(true)
        if(validaEnvio('cadastro') && validaEnvio('coletaDados') && validaEnvio('regulamento')) 
            verificaChassi()
        else
           setLoad(false)

        
    }

    const sendCadastro = (veiculoId) => {
        if (veiculoId) {
            let objEnvio = {}
            objEnvio.nomeProprietario = cadastro.nome
            objEnvio.emailProprietario = cadastro.email
            objEnvio.cpfProprietario = cadastro.cpf
            objEnvio.telefoneProprietario = cadastro.telefone
            objEnvio.chassiProprietario = cadastro.chassi
            objEnvio.politicaPrivacidade = cadastro.permission
            objEnvio.regulamento = cadastro.regulamento
            objEnvio.veiculoId = veiculoId
            objEnvio.numSorteio = cadastro.sorteio
    
            trackPromise(
                ApiServices.apiPost(`sorteios/adicionar`, objEnvio)
                .then(res => {
                    if (res.success) {
                        getNumeroSorteio(cadastro.chassi);
                        setStatusRet({"show": 'finished', "msg": ''})
                    } else {
                        props.showMsgW(res.errors)
                    }
                }).catch(res => {
                    //alert(JSON.stringify(res));
                    //alert('busca cpf erro');
                    props.showMsgW(`Erro ao buscar cpf-Tente novamente dentro de alguns minutos`)
                    setLoad(false) 
                })
            )
        } else {
            props.showMsgW(`Não foi possivel identificar o id do veículo`)
        }
        
    }
    

    const verificaChassi = () => {
        trackPromise(
            ApiServices.apiPost(`sorteios/buscarChassi?chassi=${cadastro.chassi}`, {})
            .then(res => {
                if (res.success) {
                    setCadastro(prevState => ({...prevState,
                        veiculoId: res.data
                    }))
                    sendCadastro(res.data)
                } else {

                    props.showMsgW(res.errors)
                    setLoad(false) 
                    if (res.errors[0] === "Chassi não envolvido no Recall de Airbag Takata.") {
                        setStatusRet({
                            "show": 'invalido', 
                            "msg": ''
                        })
                    } else if (res.errors[0] === "Esse Chassi já está cadastrado em nossa base.") {
                        setStatusRet({
                            "show": 'cadastrado', 
                            "msg": ''
                        })
                    }
                    
                }
                
            }).catch(res => {
                //alert(JSON.stringify(res));
                props.showMsgW(`Erro ao buscar cpf`)
                setLoad(false) 
            })
        )
    }

    const resetModal = () => {
        props.modalCloseForm()

        setCadastro({
            "nome": '', 
            "email": '', 
            "cpf": '', 
            "telefone": '',
            "chassi": '',
            "permission": false,
            "regulamento": false,
            "veiculoId": ''
        })
        setLoad(false)
        setStatusRet({
            "show": 'inicial', 
            "msg": ''
        })
    }

    const validaEnvio  = (id) => {
        let countError = 0;
        let errorFields = [];
        let msgType = 0;
        let countFields = 0;
        let ret = true;

        if (id == 'cadastro') {
            if (cadastro.nome === "" || cadastro.nome === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("Nome");
            }
            if (cadastro.email === "" || cadastro.email === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("E-mail");
            }
            if (cadastro.cpf === "" || cadastro.cpf === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("CPF");
            }
            if (cadastro.telefone === "" || cadastro.telefone === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("Telefone");
            }
            if (cadastro.chassi === "" || cadastro.chassi === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("Chassi");
            }
            
        }
        if (id == 'coletaDados') {
            if (!cadastro.permission) {
                ret = false;
                msgType = 1;
            }
            
        }
        if (id == 'regulamento') {
            if (!cadastro.regulamento) {
                ret = false;
                msgType = 2;
            }
        }
    

        // valida confirmação de senha
        if (id === 'chassiValido') {
            if (cadastro.nome === "" || cadastro.nome === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("Nome");
            }
        }

        //fim valida busca

        if (errorFields.length === 1 && msgType === 0) {
            if (errorFields[0] === "Celular") {
                props.showMsgW(
                    "O campo " + errorFields[0] + " deve ter pelo menos 10 digitos"
                );
            } else {
                props.showMsgW(
                    "O campo " + errorFields[0] + " é obrigatório."
                );
            }
        }

        if (errorFields.length === 2 && msgType === 0) {
            let concatString = "";
            concatString += errorFields[0] + " e " + errorFields[1];
            props.showMsgW(
                "Os campos " + concatString + " são obrigatórios."
            );
        }

        if (errorFields.length > 2 && msgType === 0) {
            let concatString = "";
            for (let n = 0; n < errorFields.length - 1; n++) {
                concatString += errorFields[n] + ", ";
            }
            concatString = concatString.slice(0, concatString.lastIndexOf(","));
            concatString += " e " + errorFields[errorFields.length - 1];
            props.showMsgW(
                "Os campos " + concatString + " são obrigatórios."
            );
        }

        //envio de mensagens
        if (msgType === 1) {
            props.showMsgW("Autorize a General Motors a coletar seus dados");
        }
        if (msgType === 2) {
            props.showMsgW("Aceite o regulamento");
        }

        return ret;
          
    }

    const getNumeroSorteio = (chassi) => { 

        trackPromise(
            ApiServices.apiGet(`numero/sorteio/${chassi}`)
            .then(res => {
                if (res.success) {
                   
                    setNumeroSorteio(res.data.numeroSorteio);
                } else {
                    props.showMsgW(res.errors)
                }
            }).catch(res => {
                props.showMsgW(`Erro ao buscar número do sorteio.`)
            })
        )
    };

    function handleVerify(valor){
        //console.log(valor);
        props.handleVerify(valor);
    }
    
    return (
        <>
            <GoogleReCaptcha onVerify={handleVerify} />
            {
                statusRet.show !== 'finished' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <div className=" tituloModal">{props.tipoForm === false ? "Chassis não Localizado no Sorteio" : ""}</div>
                        <div className="espaço-modal">Faça seu Cadastro</div>
                        <IconButton aria-label="close" onClick={props.modalCloseForm}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        
                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Nome do proprietário (conforme CRLV do veículo)</label>
                                            <input type="text" className="form-control" id="nome" maxLength="200"
                                                value={cadastro.nome} onChange={handleChangeForm('nome')} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>E-mail do proprietário</label>
                                            <input type="email" className="form-control" id="email" maxLength="100"
                                                value={cadastro.email} onChange={handleChangeForm('email')} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label>CPF do proprietário</label>
                                            <input type="text" className="form-control" id="cpf"
                                                value={cadastro.cpf} onChange={handleChangeForm('cpf')} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label>Telefone do proprietário</label>
                                            <input type="text" className="form-control" id="telefone"
                                                value={cadastro.telefone} onChange={handleChangeForm('telefone')} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label>Chassi do veículo</label>
                                            <input type="text" className="form-control" id="chassi" maxLength="17"
                                                value={cadastro.chassi} onChange={handleChangeForm('chassi')} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                {statusRet.show === 'cadastrado' &&
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="alert info">
                                                Esse Chassi já está cadastrado em nossa base. <br/>
                                                Em caso de dúvidas, entre em contato com 
                                                recallchevrolet@gm.com.
                                            </div>
                                        </div>
                                    </div>
                                }
                                {statusRet.show === 'invalido' &&
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="alert info">
                                                Chassi não envolvido no Recall de Airbag Takata. <br/>
                                                Saiba se seu veículo está participando de algum outro Recall da Chevrolet. <br/>

                                                <a href="https://www.chevrolet.com.br/servicos/recalls" target="_blank" rel="noopener noreferrer">Consulte</a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="checkboxContainer">
                                            <Checkbox checked={cadastro.permission} onChange={handleChangeForm('permission')} name="checkedPermission" inputProps={{ 'aria-label': 'primary checkbox' }} />
                                            <p>
                                                Autorizo a General Motors a coletar, armazenar e tratar os meus dados pessoais, compartilha-los
                                                com a SECAP/ME, com a rede de concessionárias Chevrolet e com os parceiros necessários para execução
                                                desta promoção, bem como utilizá-los para comunicações por aplicativos de mensagens instantâneas
                                                (WhatsApp, Telegram, etc.), SMS, ligações e e-mails nos termos 
                                                previstos na <a href="https://www.chevrolet.com.br/ajuda/politica-de-privacidade" target="_blank" rel="noopener noreferrer">política de privacidade</a>
                                            </p>
                                        </div> 
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="checkboxContainer">
                                            <Checkbox checked={cadastro.regulamento} onChange={handleChangeForm('regulamento')} name="checkedRegulamento" inputProps={{ 'aria-label': 'primary checkbox' }} />
                                            <p>
                                                Li e estou de acordo com os termos do regulamento do Sorteio
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 offset-md-3 col-sm-12 mt20 center">
                                        {
                                            !load ?
                                            <button className="btn btn-primary btn-block btn-lg" onClick={validaCadastro}> Enviar </button>
                                            :
                                            <button className="btn btn-primary btn-block btn-lg" > Processando ... </button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                
                    </DialogContent>
                </>
            }
            

            {statusRet.show === 'finished' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <IconButton aria-label="close" onClick={resetModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center">
                                <h2 className="titleEspecialSorteio">
                                    {cadastro.nome} <br/>
                                    Obrigado por se cadastrar.
                                </h2>
                                <p className="mb40">
                                    Você receberá um e-mail no endereço <strong>{cadastro.email} </strong>
                                    com seu número da sorte para concorrer até 3 (três) Onix 0km.
                                </p>
                                <hr className="linhaAmarela"></hr>
                                        <div className="col-md-12 col-sm-12 center">
                                    
                                    <p className="mb40 titulo-modal">
                                    Anote seu número da sorte:<br/>
                                     <div className="NumSorteio">
                                         {numeroSorteio} 
                                    </div>
                                    </p>
                                    
                                </div>

                                <Regulamento textoBtn={'Conheça regulamento do sorteio'} styleBtn={'container'} origem={'sorteio'} />
                            </div>
                        </div>
                   
                        <div className="row">
                           
                        </div>
                    </DialogContent>
                    </>
            }
        </>
    )
};

const FormConsultaGanhador = (props) => {
    const [consulta, setConsulta] = React.useState({
        "chassi": '', 
        "cpf": '', 
    });

    const [numeroSorteio, setNumeroSorteio] = React.useState('');

    const [load, setLoad] = React.useState(false)
    const [statusRet, setStatusRet] = React.useState({
        "show": 'inicial', 
        "msg": ''
    })

    const handleChangeForm = (campo = '') => event => {
        event.persist();
        //debugger
        switch (campo) {
            case 'chassi':
                setConsulta(prevState => ({...prevState,
                    [campo]: event.target.value
                }))
                break;
            case 'cpf':
                setConsulta(prevState => ({...prevState,
                    [campo]: Mask.cpf(event.target.value)
                }))
                break;
            default:
                
        }
    };

    const validaConsulta = () => {
        setLoad(true)
        if(validaEnvio('consulta')) 
            verificaGanhador()
        else
           setLoad(false)

        
    }
    

    const verificaGanhador = () => {
        let objEnvio = {}
        objEnvio.chassi = consulta.chassi
        objEnvio.cpfProprietario = consulta.cpf

        setStatusRet({"show": 'roulette', "msg": ''})
        
        setTimeout(function(){
            trackPromise(
                ApiServices.apiPost(`sorteios/ganhador`, objEnvio)
                .then(res => {
                    if (res.success) {
                        console.log(res.data.status);
                        if (res.data.status == 0)
                        {
                            setStatusRet({"show": 'notFound', "msg": ''})
                        }
                        else if (res.data.status == 1)
                        {
                            setNumeroSorteio(res.data.numeroSorteio);
                            setStatusRet({"show": 'notWon', "msg": ''})
                        }
                        else if (res.data.status == 2)
                        {
                            setNumeroSorteio(res.data.numeroSorteio);
                            setStatusRet({"show": 'earner', "msg": ''})
                        }
                    } else {
                        props.showMsgW(res.errors)
                    }
                }).catch(res => {
                    props.showMsgW(`Erro ao buscar ganhador.`)
                    setLoad(false) 
                })
            )
        }, 5000);
    }

    const resetModal = () => {
        props.modalCloseFormConsultaGanhador()

        setConsulta({
            "chassi": '', 
            "cpf": '', 
        })
        setLoad(false)
        setStatusRet({
            "show": 'inicial', 
            "msg": ''
        })
    }

    const validaEnvio  = (id) => {
        let errorFields = [];
        let msgType = 0;
        let ret = true;

        if (id == 'consulta') {
            if (consulta.chassi === "" || consulta.chassi === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("Chassi");
            }

            if (consulta.cpf === "" || consulta.cpf === undefined) {
                ret = false;
                msgType = 0;
                errorFields.push("CPF");
            }
        }

        if (errorFields.length > 0 && msgType === 0) {
            let concatString = "";
            for (let n = 0; n < errorFields.length - 1; n++) {
                concatString += errorFields[n] + ", ";
            }
            concatString = concatString.slice(0, concatString.lastIndexOf(","));
            concatString += " e " + errorFields[errorFields.length - 1];
            props.showMsgW(
                "Os campos " + concatString + " são obrigatórios."
            );
        }

        return ret;
          
    }

    
    return (
        <>
            {
                statusRet.show !== 'earner' && statusRet.show !== 'roulette' && statusRet.show !== 'notWon' && statusRet.show !== 'notFound' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <div className="espaço-modal title1seuSorteio">Quer saber se você foi o ganhador no primeiro sorteio da promoção.</div>
                        <div className="espaço-modal title2seuSorteio">"Seu Chevrolet Seguro - Campanha Sorteio 3 Onix 0km?"</div>
                        <IconButton aria-label="close" onClick={props.modalCloseFormConsultaGanhador}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <input placeholder="Digite seu Chassi" type="text" className="form-control" id="nome" maxLength="200"
                                        value={consulta.chassi} onChange={handleChangeForm('chassi')} 
                                    />
                                    
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <input placeholder="Digite seu CPF" type="email" className="form-control" id="email" maxLength="100"
                                        value={consulta.cpf} onChange={handleChangeForm('cpf')} 
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-sm-12 mt20 center">
                                {
                                    !load ?
                                    <button className="btn btn-primary btn-block btn-lg" onClick={validaConsulta}> Enviar </button>
                                    :
                                    <button className="btn btn-primary btn-block btn-lg" > Processando ... </button>
                                }
                                
                            </div>
                        </div>
                
                    </DialogContent>
                </>
            }
            
            {statusRet.show === 'roulette' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <IconButton aria-label="close" onClick={resetModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center">
                                <img src={rouletteImg} alt="banner" className="d-none d-md-block d-lg-block responsive mAuto" />
                               
                            </div>
                        </div>
                    </DialogContent>
                    </>
            }

            {statusRet.show === 'earner' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <IconButton aria-label="close" onClick={resetModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center">
                                <h2 className="titleResultadoSorteio">
                                    
                                    O número da sorte premiado foi:
                                </h2>
                                <div className="NumSorteio">
                                    {numeroSorteio} 
                                </div>
                                <hr className="linhaAmarelaSorteio"></hr>
                                        <div className="col-md-12 col-sm-12 center">
                                    
                                    <p className="mb40 titulo1-modal">
                                        Parabéns!<br/>
                                        <div className="titulo2-modal">
                                            Fique atento. Em breve a Chevrolet entrará <br/>
                                            em contato com você.
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                   
                        <div className="row">
                           
                        </div>
                    </DialogContent>
                    </>
            }

            {statusRet.show === 'notWon' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <IconButton aria-label="close" onClick={resetModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center">
                                <h2 className="titleResultadoSorteio">
                                    
                                    O número da sorte premiado foi:
                                </h2>
                                <div className="NumSorteio">
                                    {numeroSorteio} 
                                </div>
                                <hr className="linhaAmarelaSorteio"></hr>
                                        <div className="col-md-12 col-sm-12 center">
                                    
                                    <p className="mb40 titulo1-modal">
                                        Que pena :(<br/>
                                        <div className="titulo2-modal">
                                            Não foi dessa vez, mas não desanime. <br/>
                                            Você continua concorrendo! <br />
                                            Fique atento aos próximos sorteios.
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                   
                        <div className="row">
                           
                        </div>
                    </DialogContent>
                    </>
            }

            {statusRet.show === 'notFound' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <IconButton aria-label="close" onClick={resetModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center">
                                <h2 className="titleResultadoSorteio">
                                   Não conseguimos localizar um cadastro<br/>
                                   para esse chassi/CPF.
                                </h2>
                                <div className="col-md-12 col-sm-12 center">
                                    
                                    <p className="mb40 titulo1-modal">
                                        Quer saber como participar <br/>
                                        dos próximos sorteios?<br/>
                                    </p>
                                </div>

                                <Regulamento textoBtn={'Consulte'} styleBtn={'container'} origem={'sorteio'} />
                            </div>
                        </div>
                   
                        <div className="row">
                           
                        </div>
                    </DialogContent>
                    </>
            }
        </>
    )
};

const FormNumeroSorteio = (props) => {

    const [statusRet, setStatusRet] = React.useState({
        "show": 'finished', 
        "msg": ''
    })

    const resetModal = () => {
        props.modalCloseFormNumeroSorteio()

        setStatusRet({
            "show": 'inicial', 
            "msg": ''
        })
    }
    
    return (
        <>

            {statusRet.show === 'finished' &&
                <>
                    <DialogTitle id="alert-dialog-title" >
                        <IconButton aria-label="close" onClick={resetModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 center">
                                <h2 className="titleseuSorteio">
                                    {props.nome} <br/>
                                </h2>
                                <p className="mb40 cadastroLocalizado">
                                    Localizamos o seu cadastro!
                                </p>
                                <hr className="linhaAmarela"></hr>
                                        <div className="col-md-12 col-sm-12 center">
                                    
                                    <p className="mb40 titulo-modal">
                                    Anote seu número da sorte:<br/>
                                    <div className="NumSorteio">
                                         {props.numeroSorteio} 
                                    </div>
                                    </p>
                                    
                                </div>

                                <Regulamento textoBtn={'Conheça regulamento do sorteio'} styleBtn={'container'} origem={'sorteio'} />
                            </div>
                        </div>
                   
                        <div className="row">
                           
                        </div>
                    </DialogContent>
                    </>
            }
        </>
    )
};

function Sorteio(props) {

    const [open, setOpen] = React.useState(false);
    const modalOpenForm = () => { setOpen(true) };
    const modalCloseForm = () => { setOpen(false) };

    const [openNumeroSorteio, setOpenNumeroSorteio] = React.useState(false);
    const modalOpenFormNumeroSorteio = () => { setOpenNumeroSorteio(true) };
    const modalCloseFormNumeroSorteio = () => { setOpenNumeroSorteio(false) };

    const [openConsultaGanhador, setOpenConsultaGanhador] = React.useState(false);
    const modalOpenFormConsultaGanhador = () => { setOpenConsultaGanhador(true) };
    const modalCloseFormConsultaGanhador = () => { setOpenConsultaGanhador(false) };

    const [tipoForm, setTipoForm] = React.useState(true);
    const [tipoFormConsultaGanhar, setTipoFormConsultaGanhador] = React.useState(true);
    const [chassi, setChassi] = React.useState('');
    const [numeroSorteio, setNumeroSorteio] = React.useState('');
    const [nome, setNome] = React.useState('');

    //const { executeRecaptcha } = useGoogleReCaptcha();
    //const { executeRecaptcha } = (this.props as IWithGoogleReCaptchaProps)
    //  .googleReCaptchaProps;

    //const handleReCaptchaVerify = useCallback(async () => {
    //    //alert('start');
    //    //window.grecaptcha.ready(() => {
    //    //    window.grecaptcha.execute("6LdDhDQeAAAAABpc_RDWQOPBWuJMqTDB-99kQ5qX", { action: 'submit' }).then(token => {
    //    //      //submitData(token);
    //    //    });
    //    //});
    //    //const { executeRecaptcha } = this.props.googleReCaptchaProps;
    //    alert('Test-handleReCaptchaVerify');
    //    alert(executeRecaptcha);
    //    if (!executeRecaptcha) {
    //      console.log('Execute recaptcha not yet available');
    //      return;
    //    }
    //    alert('Ponto vvvv');
    //
    //    const token = await executeRecaptcha('yourAction');
    //    alert('outro token:' + token);
    //    // Do whatever you want with the token
    //}, []);

    //const Demo = props => (
    //    <ScriptTag type="text/javascript" src="https://www.google.com/recaptcha/api.js?render=6LdDhDQeAAAAABpc_RDWQOPBWuJMqTDB-99kQ5qX" />
    //)

    //const Demo = props => (
    //    <div className="application">
    //        <Helmet>
    //            <script src="https://www.google.com/recaptcha/api.js?render=6LdDhDQeAAAAABpc_RDWQOPBWuJMqTDB-99kQ5qX" type="text/javascript" />
    //        </Helmet>
    //    </div>
    //      
    //);

    const [openNew, setOpenNew] = React.useState(false);
    const modalOpenNew = () => {
        setOpenNew(true);
      };
      const modalCloseNew = () => {
        setOpenNew(false);
      };

    const changeChassi = event => {
        if (event.target.value.length <= 17) {
            setChassi(event.target.value)
        }
    };

    const getNumeroSorteio = event => { 

        trackPromise(
            ApiServices.apiGet(`numero/sorteio/${chassi}`)
            .then(res => {
                if (res.success) {
                  
                    if(res.data.statusCodigo === "4")
                    {
                        setNumeroSorteio(res.data.numeroSorteio);
                        setNome(res.data.nome);
                        modalOpenFormNumeroSorteio();
                    }else
                    {
                        setTipoForm(false);
                        modalOpenForm();
                    }
                } else {
                    props.showMsgW(res.errors)
                }
            }).catch(res => {
                props.showMsgW(`Erro ao buscar número do sorteio.`)
            })
        )
    };

    const openFormCadastro = event => { 

        setTipoForm(true);
        modalOpenForm();
    };

    const openFormConsultarGanhador = event => { 

        setTipoFormConsultaGanhador(true);
        // modalOpenForm();

        modalOpenFormConsultaGanhador();
    };

    const preventDefault = (event) =>{
        window.location.href = 'https://www.chevrolet.com.br/servicos/recalls/airbag-takata/solicitar-troca';
      };

    useEffect(() => {
        modalOpenNew();
    }, []);


    function handleVerify(valor){
        //console.log(valor);
        props.handleVerify(valor);
    }
    
    return (
        <Fragment>
            <GoogleReCaptcha onVerify={handleVerify} />
            {/* <Banner imgTitulo={bannerTitle} imgMain={bannerImg} igmBg={bannerBg} type={'car'} />  */}

            <div className="bgMainFeature">
                <a onClick={openFormConsultarGanhador} target="_blank" rel="noopener noreferrer" ><img src={bannerImgSorteioV2} alt="banner" className="d-none d-md-block d-lg-block responsive mAuto" /></a>
                <a onClick={openFormConsultarGanhador} target="_blank" rel="noopener noreferrer" > <img src={bannerImgSorteioMobileV2} alt="banner" className="d-block d-md-none d-lg-none responsive mAuto" /></a>
            </div>
            

            <div className="container contentDefault" >
                <div className='row'>
                    <div className='col-12'>
                        <h2>
                            <i>
                                Promoção <strong>"Seu Chevrolet Seguro"</strong> <br/>
                                Campanha  Sorteio de 3 (três) Onix 0km.
                            </i>
                        </h2>
                    </div>
                    <div className='col-12'>
                        <p className="big">
                            Faça o Recall de Airbags Takata dos modelos Celta (ano de fabricação 
                            2013 a 2016) ou Classic (ano de fabricação 2012 a 2016) 
                            e <strong>concorra a 3 (três) Onix 0km.</strong>
                        </p>

                        <p className="big mt30">
                            <strong>
                                Promoção exclusiva para proprietários que realizarem o Recall
                                dos Airbags Takata até 31 de Março de 2022.
                            </strong>
                        </p>
                    </div>
                    <div className='col-12 mt30'>
                        {/* <h2 className="italic">
                            AGUARDE. EM BREVE INFORMAÇÕES SOBRE A CAMPANHA SORTEIO DE ATÉ 3 (TRÊS) ONIX 0KM.
                        </h2> */}
                        <h2 className="italic">Como participar?</h2>
                        <p>
                            <strong>1. </strong>
                            Verifique no <strong><a href="/" target="_blank" rel="noopener noreferrer" >link</a></strong> se 
                            seu veículo está envolvido no   Recall de Airbags Takata.
                        </p>
                        <p>
                            <strong>2. </strong>
                            Vá até uma Concessionária Chevrolet e realize o Recall de Airbags Takata.
                        </p>
                        <p>
                            <strong>3. </strong>
                            <strong style={{cursor:'pointer'}} onClick={modalOpenForm}>Clique aqui</strong> e 
                            preencha com seus dados para cadastro 
                            conforme o proprietário constante no CRLV do veículo na data de 
                            execução do Recall dos Airbags Takata.
                        </p>
                        <p>
                            <strong>4. </strong>
                            Você receberá um e-mail com seu número da sorte.
                        </p>
                        <p>
                            <strong>5. </strong>
                            Conheça o <Regulamento styleBtn={'link'} origem={'sorteio'} /> e condições da Campanha e datas
                            dos sorteios.
                        </p>
                    </div>

                    <div className='col-12 text-center '>
                    <div className="mt30">
                            <button className="btn btn-outline-primary btn-lg" onClick={openFormCadastro}>Faça seu Cadastro</button>
                        </div>

                    <div className='col-md-8 mt30'>
                        <h3>Já fez seu cadastro? Consulte seu número da sorte:</h3>
                    </div>
                    <div className='col-md-7 mt30'>
                        
                        <div className="input-group">
                            <input type="text" className="form-control form-control-lg" 
                                placeholder="Digite seu Chassi"
                                value={chassi} onChange={changeChassi} 
                            />
                            <div className="input-group-append">
                            <button className="btn btn-info btn-lg" type="button" 
                                    onClick={getNumeroSorteio}
                                >OK</button>
                            </div>
                        </div>
                    </div>
                    <div className='row' >
                            <div className='col-sm-12'>
                                <form>
                                    {
                                        /*
                                            <ReCAPTCHA
                                                sitekey="6LdDhDQeAAAAABpc_RDWQOPBWuJMqTDB-99kQ5qX"
                                                onChange={changeRecaptcha}
                                            />    
                                            <GoogleReCaptcha onVerify={handleVerify} />
                                        */
                                    }
                                    {
                                        /*
                                    <GoogleReCaptchaProvider
                                            reCaptchaKey="6LdDhDQeAAAAABpc_RDWQOPBWuJMqTDB-99kQ5qX"

                                        >
                                            <GoogleReCaptcha onVerify={(e) => changeRecaptcha('')} />
                                    </GoogleReCaptchaProvider>    
                                        */
                                    }
                                    
                                    {/*
                                    <input type="submit" value="Teste" />
                                    <input type="button" onClick={handleReCaptchaVerify} value="Disparo manual" />
                                    */}
                                    
                                </form>
                            </div>
                    </div>

                        
                        <div className="mt30">
                            <Regulamento origem={'sorteio'} />
                        </div>
                    </div>
                </div>
            </div>

            <CallAction>
                <div className="text-center">
                    <Link to='/faq-sorteio' >
                        <HelpOutline /> Tire suas dúvidas da Campanha: 
                        SEU CHEVROLET SEGURO - SORTEIO DE 3 (TRÊS) ONIX 0KM
                    </Link>
                </div>
            </CallAction>


            <Dialog
                open={open}
                maxWidth={'md'}
                onClose={modalCloseForm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <FormCadastro showMsgS={props.showMsgS} handleVerify={props.handleVerify}  showMsgW={props.showMsgW} showMsgE={props.showMsgE} modalCloseForm={modalCloseForm} tipoForm={tipoForm} />
            </Dialog>

            <Dialog
                open={openNumeroSorteio}
                maxWidth={'md'}
                onClose={modalCloseFormNumeroSorteio}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <FormNumeroSorteio showMsgS={props.showMsgS} showMsgW={props.showMsgW} showMsgE={props.showMsgE} modalCloseFormNumeroSorteio={modalCloseFormNumeroSorteio} numeroSorteio={numeroSorteio} nome={nome} />
            </Dialog>

            <Dialog
                fullWidth={true}
                open={openConsultaGanhador}
                maxWidth={'sm'}
                onClose={modalCloseFormConsultaGanhador}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <FormConsultaGanhador showMsgS={props.showMsgS} showMsgW={props.showMsgW} showMsgE={props.showMsgE} modalCloseFormConsultaGanhador={modalCloseFormConsultaGanhador} />
            </Dialog>
        </Fragment>
    )
}


const mapStateToProps = store => ({
    snackbarState: store.snackbarState
});
const mapDispatchToProps = dispatch => bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default  connect(mapStateToProps, mapDispatchToProps)(Sorteio);